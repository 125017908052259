function combineTableData(newData, arrayData) {
	if (arrayData.lenght == 0) {
		arrayData.push([newData]);
		return;
	}
	let match = false;
	for (let i = 0; i < arrayData.length; i++) {
		let temp = arrayData[i][0];
		if (newData['primary_attribute'] == temp['primary_attribute']) {
			if (checkSameArray(newData['primary_attribute_values'], temp['primary_attribute_values']) && checkSameArray(newData['attributes'], temp['attributes'])) {
				match = true;
				arrayData[i].push(newData);
				break;
			}
		}
	}
	if (!match) {
		arrayData.push([newData]);
	}

}

function validateItemData(tempData, refKey) {

	for (let i = 0; i < tempData['attributes'].length; i++) {
		let sec = tempData['attributes'][i];
		if (!tempData['attribute_values'][sec]) {
			addError(refKey + sec);
			show_error_alert("Please Select The " + sec);
			return false;
		} else {
			removeError(refKey + sec);
		}
	}

	if (tempData['primary_attribute']) {
		let exist = false;
		for (let i = 0; i < tempData['primary_attribute_values'].length; i++) {
			let pri = tempData['primary_attribute_values'][i];
			if (tempData['qty'][pri] < 0 || !Number.isInteger(tempData['qty'][pri])) {
				addError(refKey + pri);
				show_error_alert("Please Enter Valid Quantity For " + pri);
				return false;
			} else {
				removeError(refKey + pri);
			}
			if (tempData['qty'][pri] > 0) {
				exist = true;
			}
		}
		if (!exist) {
			addError(refKey + "table");
			show_error_alert("Please Enter Valid Quantity For " + tempData.primary_attribute);
			return false;
		} else {
			removeError(refKey + "table");
		}
	} else {
		if (tempData['qty']['default'] <= 0 || !Number.isInteger(tempData['qty']['default'])) {
			addError(refKey + "qty");
			show_error_alert("Please Enter Valid Quantity")
			return false;
		} else {
			removeError(refKey + "qty");
		}
	}

	if (tempData['multiplier'] <= 0 || !Number.isInteger(tempData['multiplier'])) {
		addError(refKey + "multiplier");
		show_error_alert("Please Enter Valid Multiplier");
		return false;
	} else {
		removeError(refKey + 'multiplier')
	}

	return true;
}

function show_error_alert(msg) {
	frappe.show_alert({
		message: __(msg),
		indicator: 'Red'
	}, 5);
}

function show_success_alert(msg) {
	frappe.show_alert({
		message: __(msg),
		indicator: "Green"
	}, 5)
}


function cloneData(data) {
	return JSON.parse(JSON.stringify(data));
}

function checkSameArray(arr1, arr2) {

	if (arr1.length != arr2.length) return false;
	let sortedArr1 = arr1.slice().sort();
	let sortedArr2 = arr2.slice().sort();

	for (let i = 0; i < sortedArr1.length; i++) {
		if (sortedArr1[i] !== sortedArr2[i]) return false;
	}
	return true;

}

function getTotalBoxes(obj) {
	if (!obj['primary_attribute']) {
		return Number(obj['qty']['default']) * Number(obj['multiplier'] ? obj['multiplier'] : 1);
	}
	let total = 0;
	for (let i = 0; i < obj['primary_attribute_values'].length; i++) {
		let key = obj['primary_attribute_values'][i];
		total += Number(obj['qty'][key]) * Number(obj['multiplier'] ? obj['multiplier'] : 1);
	}
	return total;
}

function arraySort(arr) {
	arr.sort((a, b) => a.localeCompare(b));
}

function addError(refKey) {

	let element = document.getElementById(refKey);

	if (element) {
		element.classList.add("error-border-class");
		element.focus();
	}

}

function removeError(refKey) {

	let element = document.getElementById(refKey);
	if (element) {
		element.classList.remove("error-border-class");
	}
}

function validatePackingSlip(packSlipData) {

	if (packSlipData.schemes.length > 1) {
		show_error_alert("Only One Scheme Is Applicable");
		return false;
	}
	if (packSlipData.standing_instructions_display) {
		if (!packSlipData.instruction_read) {
			addError('read_instruction');
			show_error_alert("Please Read The Standard Instructions and Click the Confirm Button");
			return false;
		} else {
			removeError('read_instruction');
		}
	}
	if (packSlipData.displayableData.length == 0) {
		show_error_alert("Add an Item to Packing Slip");
		return false;
	}
	if (!packSlipData.deliver_to || packSlipData.deliver_to == '') {
		show_error_alert("Select The Delivery Address For the Customer");
		return false;
	}
	if (!packSlipData.dispatch_location || packSlipData.dispatch_location == "") {
		show_error_alert("Select The Dispatch Location");
		return false;
	}
	if (!packSlipData.transport || packSlipData.transport == "") {
		show_error_alert("Select the Transport");
		return false;
	}
	if (!packSlipData.price_list || packSlipData.price_list == '') {
		show_error_alert("Select the Price List");
		return false;
	}
	for (let i = 0; i < packSlipData.displayableData.length; i++) {
		for (let j = 0; j < packSlipData.displayableData[i].length; j++) {
			let valueEntered = false;
			if (packSlipData.packItems[i][j]['primary_attribute']) {
				for (let k = 0; k < packSlipData.packItems[i][j]['primary_attribute_values'].length; k++) {
					let key = packSlipData.packItems[i][j]['primary_attribute_values'][k];
					if (packSlipData.displayableData[i][j]['qty'][key] && !isNaN(packSlipData.displayableData[i][j]['qty'][key])) {
						let v1 = Number(packSlipData.packItems[i][j]['qty'][key]);
						if (v1 < 0 || !Number.isInteger(v1)) {
							addError("input_" + i + "" + j + "" + key);
							show_error_alert("Enter Valid Value For Variant Quantity for Variant " + key + " in Item " + packSlipData.packItems[i][j].item_name + " in order " + packSlipData.packItems[i][j]['order']);
							return false;
						} else {
							removeError("input_" + i + "" + j + "" + key);
							if (packSlipData.packItems[i][j]['qty'][key] > 0) {
								valueEntered = true;
							}
						}
					}
				}
				if (!valueEntered) {
					show_error_alert("Enter Valid Quantity For " + packSlipData.packItems[i][j]['item_name'] + " in order " + packSlipData.packItems[i][j]['order']);
					return false;
				}
			} else {
				let v1 = Number(packSlipData.packItems[i][j]['qty']['default']);
				if (!Number.isInteger(v1) || v1 <= 0) {
					addError("input_" + i + "" + j + "default");
					show_error_alert("Enter Valid Quantity For " + packSlipData.packItems[i][j]['item_name'] + " in order " + packSlipData.packItems[i][j]['order']);
					return false;
				}
				removeError("input_" + i + "" + j + "default");
			}
			let v1 = Number(packSlipData.packItems[i][j]['multiplier']);
			if (v1 <= 0) {
				addError("input_" + i + "" + "" + j + "multiplier");
				show_error_alert("Enter Valid Multiplier For " + packSlipData.packItems[i][j]['item_name'] + " in order " + packSlipData.packItems[i][j]['order']);
				return false;
			}
			removeError("input_" + i + "" + "" + j + "multiplier");
			if (Number(packSlipData.packItems[i][j]['carton']) < 0 || (packSlipData.packItems[i][j]['carton'] != null && packSlipData.packItems[i][j]['carton'] != '' && !Number.isInteger(Number(packSlipData.packItems[i][j]['carton'])))) {
				addError("input_" + i + "" + "" + j + "carton");
				show_error_alert("Enter Valid Carton Quantity For " + packSlipData.packItems[i][j]['item_name'] + " in order " + packSlipData.packItems[i][j]['order']);
				return false;
			}
			removeError("input_" + i + "" + "" + j + "carton");

		}
	}
	return true;
}


function process_error_message(data,xhr){
    var opts = {}
    var statusCode = {
        200: function(data, xhr) {
            opts.success_callback && opts.success_callback(data, xhr.responseText);
        },
        401: function(xhr) {
            if(frappe.app.session_expired_dialog && frappe.app.session_expired_dialog.display) {
                frappe.app.redirect_to_login();
            } else {
                frappe.app.handle_session_expired();
            }
        },
        404: function (xhr) {
            frappe.msgprint({
                title: __("Not found"),
                indicator: "red",
                message: __("The resource you are looking for is not available"),
            });
        },
        403: function(xhr) {
            if (frappe.session.user === "Guest" && frappe.session.logged_in_user !== "Guest") {
                // session expired
                frappe.app.handle_session_expired();
            } else if (xhr.responseJSON && xhr.responseJSON._error_message) {
                frappe.msgprint({
                    title: __("Not permitted"), indicator: 'red',
                    message: xhr.responseJSON._error_message
                });

                xhr.responseJSON._server_messages = null;
            } else if (xhr.responseJSON && xhr.responseJSON._server_messages) {
                var _server_messages = JSON.parse(xhr.responseJSON._server_messages);

                if (_server_messages.indexOf(__("Not permitted")) !== -1) {
                    return;
                }
            } else {
                frappe.msgprint({
                    title: __("Not permitted"), indicator: 'red',
                    message: __('You do not have enough permissions to access this resource. Please contact your manager to get access.')});
            }


        },
        508: function(xhr) {
            frappe.utils.play_sound("error");
            frappe.msgprint({title:__('Please try again'), indicator:'red',
                message:__("Another transaction is blocking this one. Please try again in a few seconds.")});
        },
        413: function(data, xhr) {
            frappe.msgprint({indicator:'red', title:__('File too big'), message:__("File size exceeded the maximum allowed size of {0} MB",
                [(frappe.boot.max_file_size || 5242880) / 1048576])});
        },
        417: function(xhr) {
            // var r = xhr.responseJSON;
            var r = null;
            if (!r) {
                try {
                    // r = JSON.parse(xhr.responseText);
                    r = JSON.parse(data);
                } catch (e) {
                    r = xhr.responseText;
                }
            }

            // opts.error_callback && opts.error_callback(r);
        },
        501: function(data, xhr) {
            if(typeof data === "string") data = JSON.parse(data);
            opts.error_callback && opts.error_callback(data, xhr.responseText);
        },
        500: function(xhr) {
            frappe.utils.play_sound("error");
            try {
                opts.error_callback && opts.error_callback();
                frappe.request.report_error(xhr, opts);
            } catch (e) {
                frappe.request.report_error(xhr, opts);
            }
        },
        504: function(xhr) {
            frappe.msgprint(__("Request Timed Out"))
            opts.error_callback && opts.error_callback();
        },
        502: function(xhr) {
            frappe.msgprint(__("Internal Server Error"));
        }
    };
    var status_code_handler = statusCode[xhr.status];
	console.log(status_code_handler)
    if (status_code_handler) {
        status_code_handler(xhr);
        return;
    }
}



module.exports = {
	combineTableData,
	cloneData,
	arraySort,
	validateItemData,
	getTotalBoxes,
	addError,
	removeError,
	show_error_alert,
	show_success_alert,
	validatePackingSlip,
	process_error_message
}
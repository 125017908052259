import { createApp } from 'vue';
import AttributeList from './Components/AttributeList.vue';
import AttributeValues from './Components/AttributeValues.vue';
import OrderItem from './Components/OrderItem.vue';
import PriceListManagement from './Pages/PriceListManagement.vue';
import PickListManagement from './Pages/PickListManagement.vue';
import AdditionalOrderItem from './Components/AdditionalOrderItem.vue';
import PackingSlipReport from './Components/PackingSlipReport.vue';
import CustomerCreditDisplay from './Components/CustomerCreditDisplay.vue';
import CustomerStatusChanger from './Components/CustomerStatusChanger.vue';
import PackSlipEdit from './Pages/PackSlipEdit.vue';
import ItemwisePendingReport from './Pages/ItemwisePendingReport.vue';
import CustomerwiseItemPendingReport from './Components/CustomerwiseItemPendingReport.vue';
import DatewiseItemPacked from './Pages/DatewiseItemPacked.vue';
import CustomerwisePackingSlipReport from './Components/CustomerwisePackingSlipReport.vue';
import PendingOrderUpdateReq from './Components/PendingOrderUpdateReq.vue';
import SMSMigrationViewer  from './Components/SMSMigrationViewer.vue';
import MultiOrderCancelItemDisplay from './Components/MultiOrderCancelItemDisplay.vue';
 
frappe.provide('frappe.essdee_sales.ui');
frappe.provide('frappe.essdee_sales.OMS.ui');

frappe.essdee_sales.OMS.ui.ItemAttributeValues = class {
    constructor({ wrapper, attr_values, attr_name } = {}) {
        this.$wrapper = $(wrapper);
        this.attr_values = attr_values;
        this.attr_name = attr_name;
        this.make_body();
    }
    
    make_body() {
        this.$page_container = $('<div class="attribute-value-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(AttributeValues);
        this.app.mount(this.$wrapper.get(0));
        // this.vue = new Vue({
        //     el: '.attribute-value-template',
        //     render: h => h(AttributeValues, {
        //     }),
        // });
    }
};

frappe.essdee_sales.OMS.ui.AttributeList = class {

    constructor(wrapper, attr_list) {
        this.$wrapper = $(wrapper);
        this.attr_list = attr_list;
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(AttributeList, { attrList: this.attr_list });
        this.vue = this.app.mount(this.$wrapper.get(0));

    }

}

frappe.essdee_sales.OMS.ui.PriceListManagement = class {

    constructor(wrapper) {
        this.$wrapper = $(wrapper);
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(PriceListManagement);
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

}

frappe.essdee_sales.OMS.ui.OrderItem = class {

    constructor(wrapper, order_details) {
        this.$wrapper = $(wrapper);
        this.order_details = order_details;
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(OrderItem, { order_details: this.order_details });
        SetVueGlobals(this.app)
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

    change_data(itemName) {
        this.vue.change_data(itemName);
    }

    get_data() {
        return this.vue.get_data();
    }

}

frappe.essdee_sales.OMS.ui.AdditionalOrderItem = class {

    constructor(wrapper,dialog) {
        this.$wrapper = $(wrapper);
        this.currentDialog = dialog;
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(AdditionalOrderItem,{dialog : this.currentDialog});
        SetVueGlobals(this.app)
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

    change_data(itemName) {
        this.vue.change_data(itemName);
    }

    get_data() {
        return this.vue.get_data();
    }

}

frappe.essdee_sales.OMS.ui.PickListManagement = class {

    constructor(wrapper) {
        this.$wrapper = $(wrapper);
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(PickListManagement);
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

}

frappe.essdee_sales.OMS.ui.PackingSlipReport = class {

    constructor(wrapper,object) {
        this.$wrapper = $(wrapper);
        this.object = JSON.parse(object);
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(PackingSlipReport,{pack_slip_details : this.object});
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

}

frappe.essdee_sales.OMS.ui.CustomerCreditDisplay = class {

    constructor(wrapper,object) {
        this.$wrapper = $(wrapper);
        this.object = object;
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(CustomerCreditDisplay,{credit_details:JSON.parse(this.object)});
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

}

frappe.essdee_sales.OMS.ui.CustomerStatusChanger = class {

    constructor(wrapper,object) {
        this.$wrapper = $(wrapper);
        this.object = JSON.parse(object);
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(CustomerStatusChanger,{
            credit_status : this.object['status'],
            allow_order : this.object['allow_order'],
            allow_packing : this.object['allow_packing']
        });
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

    get_data() {
        return this.vue.get_data();
    }

}


frappe.essdee_sales.OMS.ui.PackSlipEdit = class {

    constructor(wrapper,object) {
        this.$wrapper = $(wrapper);
        this.object = object;
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(PackSlipEdit,{ps_id : this.object});
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

}

frappe.essdee_sales.OMS.ui.ItemwisePendingReport = class {

    constructor(wrapper) {
        this.$wrapper = $(wrapper);
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(ItemwisePendingReport);
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

}

frappe.essdee_sales.OMS.ui.CustomerwiseItemPendingReport = class {

    constructor(wrapper,object) {
        this.$wrapper = $(wrapper);
        this.object = object;
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(CustomerwiseItemPendingReport,{item_name : this.object});
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

    makePickList() {
        return this.vue.makePickList();
    }

    makeBulkItemCancel() {
        return this.vue.makeBulkItemCancel();
    }

}

frappe.essdee_sales.OMS.ui.DatewiseItemPacked = class {

    constructor(wrapper) {
        this.$wrapper = $(wrapper);
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(DatewiseItemPacked);
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

}

frappe.essdee_sales.OMS.ui.CustomerwisePackingSlipReport = class {

    constructor(wrapper,object) {
        this.$wrapper = $(wrapper);
        this.object = JSON.parse(object);
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(CustomerwisePackingSlipReport,
            {
                item_name : this.object.item_name,
                from_date : this.object.from_date,
                to_date : this.object.to_date,
                sales_partner : this.object.sales_part,
                customer : this.object.cust
            }
        );
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

}

frappe.essdee_sales.OMS.ui.PendingOrderUpdateReq = class {

    constructor(wrapper,order,pending,update) {
        this.$wrapper = $(wrapper);
        this.order = order;
        this.pending = pending;
        this.update = update;
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(PendingOrderUpdateReq,{order : this.order,pending : this.pending,update : this.update});
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

    createUpdateReq() {
        return this.vue.createUpdateReq();
    }
    
}

frappe.essdee_sales.OMS.ui.SMSMigrationViewer = class {

    constructor(wrapper) {
        this.$wrapper = $(wrapper);
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(SMSMigrationViewer);
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }
}

frappe.essdee_sales.OMS.ui.MultiOrderCancelItemDisplay = class {

    constructor(wrapper, items) {
        this.$wrapper = $(wrapper);
        this.items = items;
        this.make_content();
    }

    make_content() {
        this.$page_container = $('<div class="dependent-attribute-template frappe-control">').appendTo(this.$wrapper);
        this.app = createApp(MultiOrderCancelItemDisplay, {
            items : this.items
        });
        SetVueGlobals(this.app);
        this.vue = this.app.mount(this.$wrapper.get(0));
    }

}

<template>
<div>
    <div class="table-responsive mt-2" v-if="originalData.length!=0">
        <h4>Original Items</h4>
        <hr>
        <table v-if="originalData.length!=0">
            <tr v-for="(items) in originalData" :key="items"  >
              <table class="table table-bordered" v-if="items.length!=0">
                  <thead class="grid-heading-row">
                      <tr>
                          <th>Item Name</th>
                          <th v-for="sec in items[0]['attributes']" :key="sec">{{sec}}</th>
                          <template v-if="items[0].primary_attribute">
                              <th v-for="pri in items[0]['primary_attribute_values']" :key="pri">{{pri}}</th>
                          </template>
                          <th>Comments</th>
                          <template v-if="!items[0]['primary_attribute']">
                              <th>Quantity</th>
                          </template>
                          <th class="fixed">Multiplier</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(item1) in items" :key="item1">
                          <td>{{item1.item_name}}</td>
                          <td v-for="sec in items[0]['attributes']" :key="sec">{{item1['attribute_values'][sec]}}</td>
                          <template v-if="item1['primary_attribute']">
                              <td v-for="pri in items[0]['primary_attribute_values']" :key="pri">{{(item1['qty'][pri] && item1['qty'][pri]!=0)? item1['qty'][pri] : '--'}}</td>
                          </template>
                          <td>{{item1.comment}}</td>
                          <template v-if="!item1['primary_attribute']">
                              <td>{{item1['qty']['default']}}</td>
                          </template>
                          <td>{{item1['multiplier']}}</td>
                      </tr>
                  </tbody>
              </table>
          </tr>
        </table>
    </div>
    <div class="table-responsive" v-if="cancelledData.length!=0">
        <h4>Cancelled Items</h4>
        <hr>
        <table v-if="cancelledData.length!=0">
            <tr v-for="(items) in cancelledData" :key="items"  >
              <table class="table table-bordered" v-if="items.length!=0">
                  <thead class="grid-heading-row">
                      <tr>
                          <th>Item Name</th>
                          <th v-for="sec in items[0]['attributes']" :key="sec">{{sec}}</th>
                          <template v-if="items[0].primary_attribute">
                              <th v-for="pri in items[0]['primary_attribute_values']" :key="pri">{{pri}}</th>
                          </template>
                          <th>Comments</th>
                          <template v-if="!items[0]['primary_attribute']">
                              <th>Quantity</th>
                          </template>
                          <th class="fixed">Multiplier</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(item1) in items" :key="item1">
                          <td>{{item1.item_name}}</td>
                          <td v-for="sec in items[0]['attributes']" :key="sec">{{item1['attribute_values'][sec]}}</td>
                          <template v-if="item1['primary_attribute']">
                              <td v-for="pri in items[0]['primary_attribute_values']" :key="pri">{{(item1['qty'][pri] && item1['qty'][pri]!=0)? item1['qty'][pri] : '--'}}</td>
                          </template>
                          <td>{{item1.comment}}</td>
                          <template v-if="!item1['primary_attribute']">
                              <td>{{item1['qty']['default']}}</td>
                          </template>
                          <td>{{item1['multiplier']}}</td>
                      </tr>
                  </tbody>
              </table>
          </tr>
        </table>
    </div>
</div>
    
</template>

<script>
import { combineTableData } from '../Utils';

export default {
  name: 'OriginalCancelOrderItem',
  props: {
    order: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      originalData: [],
      cancelledData: []
    };
  },
  methods: {
    fetchDisplayData(order) {
      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.order.order.make_original_cancel_items',
        args: {
          order: order
        },
        callback: (response) => {
          response = response.message;
          if (response.status === 200) {
            for (let i = 0; i < response.data.original_data.length; i++) {
              combineTableData(response.data.original_data[i], this.originalData);
            }
            for (let i = 0; i < response.data.cancelled_data.length; i++) {
              combineTableData(response.data.cancelled_data[i], this.cancelledData);
            }
            this.originalData.sort((a, b) => a[0].item_name.localeCompare(b[0].item_name));
            this.cancelledData.sort((a, b) => a[0].item_name.localeCompare(b[0].item_name));
          }
        }
      });
    }
  },
  mounted() {
    this.fetchDisplayData(this.order);
  }
};
</script>

<style scoped>

table{
    width: 100%;
}

h4{
    padding: 1rem;
    padding-bottom: 0%;
    border-radius: 5rem;
    max-width: max-content;

}

.success{
    background-color: rgb(134, 194, 134);
}

.danger{
    background-color: rgb(230, 136, 136);
}


</style>
<template>
    <div class="msg-box no-border">
		<div>
			<img src="/assets/frappe/images/ui-states/list-empty-state.svg" alt="Generic Empty State" class="null-state">
		</div>
		<p>Nothing To Display</p>		
	</div>
</template>

<script>
export default {
    name : 'NothingToDisplay'
}
</script>
<style scoped>
.msg-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

img{
    height: 100px;
    width: 100px;
    margin: 1rem;
}

.null-state {
  max-width: 100%;
  height: auto;
}

.no-border {
  border: none;
}
</style>
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e04ae707"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "multi-select",
  ref: "multiSelect"
}
const _hoisted_2 = { class: "selected-options" }
const _hoisted_3 = {
  key: 0,
  class: "placeholder"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "options-list"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 1 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "dropdown",
      onClick: _cache[0] || (_cache[0] = (...args) => ($options.toggleDropdown && $options.toggleDropdown(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        (!$data.selectedValues.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.placeholder), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selectedValues, (value, index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: index,
            class: "tag"
          }, [
            _createTextVNode(_toDisplayString(value['option']) + " ", 1 /* TEXT */),
            _createElementVNode("button", {
              onClick: _withModifiers($event => ($options.removeOption(value)), ["stop"]),
              class: "remove-btn"
            }, "x", 8 /* PROPS */, _hoisted_4)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    ($data.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          ($props.options.length!=0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.options, (option) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (!$data.selectedValues.includes(option))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: option,
                        onClick: _withModifiers($event => ($options.selectOption(option)), ["stop"]),
                        class: _normalizeClass({ selected: $data.selectedValues.includes(option) })
                      }, _toDisplayString(option['option']), 11 /* TEXT, CLASS, PROPS */, _hoisted_6))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              }), 256 /* UNKEYED_FRAGMENT */))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, "No Options Available"))
        ]))
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */))
}
<template>
  <div class="attribute-value-template frappe-control">
    <table class="table table-sm table-bordered" v-if="attrValues">
      <tr>
        <th class="sno">S.No</th>
        <th>Attribute Value</th>
      </tr>
      <tr v-for="(attr, index) in attrValues" :key="attr">
        <td>{{ index + 1 }}</td>
        <td>{{ attr.attribute_value }}</td>
      </tr>
    </table>
    <p v-else>No available values for {{ attrName }}</p>
    <p v-if="!isNumeric">
      <button class="btn btn-xs btn-default btn-address" @click="addValue('Item Attribute Value', attrName)">
        {{ "New " + attrName }}
      </button>
    </p>
  </div>
</template>

<script>
export default {
  name: 'AttributeValues',
  data() {
    return {
      attrValues: this.getAttrValues(),
      attrName: cur_frm.doc.attribute_name,
      isNumeric: cur_frm.doc.numeric_values
    };
  },
  methods: {
    addValue(doctype, attrName) {
      frappe.model.with_doctype(doctype, () => {
        const newDoc = frappe.model.get_new_doc(doctype);
        newDoc.attribute = attrName;
        frappe.ui.form.make_quick_entry(
          doctype,
          (x) => { this.cur_frm && this.cur_frm.reload_doc(); },
          null,
          newDoc
        );
      });
    },
    getAttrValues() {
      if (cur_frm.doc.__onload.attr_values && cur_frm.doc.__onload.attr_values.length !== 0) {
        return cur_frm.doc.__onload.attr_values;
      } else {
        return null;
      }
    }
  }
};
</script>

<template>
<div ref="root" class="container  mt-4 p-2" v-show="pickListData.display">
    <h2>Pending Orders Of {{pickListData.customer}}</h2>
    <div v-for="order in Object.keys(pickListData['displayableData'])" :key="order" class="flex_col">
        <table v-if="pickListData['displayableData'][order].length!=0">
            <div class="m-2 flex_row">
                <h4>{{order}}</h4>
            </div> 
            <tr v-for="(items) in pickListData['displayableData'][order]" :key="items"  >
                <table class="table table-bordered" v-if="items.length!=0">
                    <thead class="grid-heading-row">
                        <tr>
                            <th>Order Name</th>
                            <th>Item Name</th>
                            <th v-for="key in items[0]['attributes']" :key="key">{{key}}</th>
                            <template v-if="items[0]['primary_attribute']">
                                <th v-for="key in items[0]['primary_attribute_values']" :key="key">{{key}}</th>
                            </template>
                            <template v-if="!items[0]['primary_attribute']">
                                <th>Quantity</th>
                            </template>
                            <th class="fixed">Multiplier</th>
                            <th class="fixed">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item1) in items" :key="item1" :class="item1.isPickListed? 'item_selected' : ''">
                            <td>{{item1.order}}</td>
                            <td>{{item1.item_name}}</td>
                            <td v-for="key in items[0]['attributes']" :key="key">{{item1['attribute_values'][key]}}</td>
                            <template v-if="items[0]['primary_attribute']">
                                <td v-for="key in items[0]['primary_attribute_values']" :key="key">{{(item1['qty'][key] && item1['qty'][key]!=0)? item1['qty'][key] : '--'}}</td>
                            </template>
                            <template v-if="!items[0]['primary_attribute']">
                                <td>{{item1['qty']['default']}}</td>
                            </template>
                            <td>{{item1.multiplier}}</td>
                            <td class="actions">
                                <div class="ml-1 cursor" v-if="!item1.isSelected">
                                    <button class="btn btn-success" @click="addToPackList(item1)" >Add</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </tr>
        </table>
    </div>
    <div v-if="Object.keys(pickListData['displayableData']).length==0">
        <nothing-to-display/>
    </div>
    <div class="packing_slip container">
        <div class="main border flex_col p-2 m-2">
            <div class="heading m-2">
                <h4 class="">Packing Slip</h4>
            </div>
            <div class="flex_row justify-between" style="max-height:fit-content;margin-bottom:0px;">
                <div class="pack-cus-details">
                    <div class="customer_address_select" id="address_select" ></div>
                    <div>
                        Deliver To: 
                        <textarea disabled class="address-box" rows="4" style=" resize: none;">{{ packSlipData.deliver_to}}</textarea>
                    </div>
                </div>
                <div class="pack-cus-details">
                    <div class="ps_price_list" id="price_list"></div>
                </div>
            </div>
           <div class="container table-container">
                <div class="table table-main table-responsive">
                    <div class="flex_col">
                        <table v-if="packSlipData['displayableData'].length!=0">
                            <tr v-for="(items,index1) in packSlipData['displayableData']" :key="items"  >
                                <table class="table table-bordered" v-if="items.length!=0">
                                    <thead class="grid-heading-row">
                                        <tr>
                                            <th class="sno">S.No</th>
                                            <th>Item Name</th>
                                            <th v-for="key in items[0]['attributes']" :key="key">{{key}}</th>
                                            <template v-if="items[0]['primary_attribute']">
                                                <th v-for="key in items[0]['primary_attribute_values']" :key="key">{{key}}</th>
                                            </template>
                                            <template v-if="!items[0]['primary_attribute']">
                                                <th>Quantity</th>
                                            </template>
                                            <th class="fixed">Multiplier</th>
                                            <th class="actions">Cartons</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item1,index2) in items">
                                            <tr>
                                                <td rowspan="2">{{index2+1}}</td>
                                                <td rowspan="2">{{item1.item_name}}</td>
                                                <td v-for="key in items[0]['attributes']" :key="key" rowspan="2">
                                                    {{item1['attribute_values'][key]}}
                                                </td>
                                                <template v-if="item1['primary_attribute']">
                                                    <td v-for="key in items[0]['primary_attribute_values']" :key="key">
                                                        <input class="form-control form-control-input form-min" type="text" :value="(item1['qty'][key] && item1['qty'][key] != 0)? item1['qty'][key] : '--'" disabled>
                                                    </td>
                                                </template>
                                                <template v-if="!item1['primary_attribute']">
                                                    <td>
                                                        <input class="form-control form-control-input form-min" type="text" :value="item1['qty']['default']" disabled>
                                                    </td>
                                                </template>
                                                <td class="multipliers">
                                                    <input class="form-control form-control-input form-min" type="text" :value="item1.multiplier" disabled>
                                                </td>
                                                <td class="actions">
                                                    <button type="button" class="btn btn-danger" @click="removeFromPackList(index1,index2)" aria-label="Close">&#10006;</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <template v-if="item1['primary_attribute']">
                                                    <td v-for="key in items[0]['primary_attribute_values']" :key="key">
                                                        <input class="form-control form-control-input form-min" type="number" v-model="packSlipData.packItems[index1][index2]['qty'][key]" />
                                                    </td>
                                                </template>
                                                <template v-if="!item1['primary_attribute']">
                                                    <td>
                                                        <input class="form-control form-control-input form-min" type="number" v-model="packSlipData.packItems[index1][index2]['qty']['default']" min="0" :id="'input_'+index1+index2+'default'"/>
                                                    </td>
                                                </template>
                                                <td class="multipliers">
                                                    <input class="form-control form-control-input form-min" type="number" v-model="packSlipData.packItems[index1][index2]['multiplier']" min="0" :id="'input_'+index1+index2+'multiplier'"/>
                                                </td>
                                                <td class="actions">
                                                    <input class="form-control form-control-input form-min" type="number" v-model="packSlipData.packItems[index1][index2]['carton']" min="0" :id="'input_'+index1+index2+'carton'"/>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </tr>
                        </table>
                        <div v-else>
                            <nothing-to-display/>
                        </div>
                    </div>
                </div>
                <div class="controls flex_col">
                    <button class="btn btn-success" @click="openItemAddDialog()">Add Additional Items</button>
                    <div class="flex_row justify-between">
                        <div class="flex_col gap-min">
                            <div class="transport-select" id="tranp-select"></div>
                        </div>
                        <div class="flex_col gap-min">
                            <label for="packListComments">Packing Slip Comments</label>
                            <input type="text" class="form-control comment-box" id="packListComments" placeholder="Packing Slip Comments (optional)" v-model="packSlipData.packSlipComment"/>
                        </div>
                        <div class="flex_col gap-min">
                            <label for="packListCartonCheck">Disable Carton Slip</label>
                            <input type="checkbox" id="packListCartonCheck" class="form-control check" v-model="packSlipData.disableCarton">
                        </div>
                    </div>
                    <div>
                    <div v-if="packSlipData.standing_instructions_display" class="flex_col">
                        <div class="standard-instruction" :class="packSlipData.instruction_read ? 'read' : 'unread'">
                            <h4 style="color:black">Standing Instructions!</h4>
                                <div class="form-control mb-3">
                                    {{packSlipData.standing_instructions}}
                                </div>
                                <button class="btn btn-warning" v-if="!packSlipData.instruction_read" @click="makeInstructionRead()" id="read_instruction">I have read and followed the above instructions</button>
                            </div>
                        </div>
                    </div>
                    <div class="flex_row gap-min justify-between">
                        <div class="scheme-multiselect" id="pack_schemes"></div>
                        <div class="dispatch-select" id="dispatch_select"></div>
                        <div class="packing-team-select" id="packing_team"></div>
                    </div>
                    <div class="btn-class flex_row justify-between">
                        <button class="btn btn-danger" @click="clearPackingSlip()">Clear Packing Slip</button>
                        <button class="btn btn-success" @click="finishPackingSlip()">Finish Packing Slip</button>
                    </div>
                </div>
            </div>
        </div> 
    </div> 
</div>
</template>

<script>
import NothingToDisplay from './NothingToDisplay.vue';

export default {
    name: 'PackingSlipCreateUpdate',
    
    props: [
        'pickListData',
        'packSlipData',
        'addToPackList',
        'removeFromPackList',
        'addPackListItem',
        'clearPackingSlip',
        'finishPackingSlip',
        'makeInstructionRead',
        'action'
    ],

    components: {
        NothingToDisplay
    },

    data() {

        return {
            sample_doc: {},
            address_select: {},
            address_template: null,
            transport_select: {},
            price_list_select: {},
            scheme_multi_select: {},
            dispatch_location_select : {},
            packing_team : {},
        };

    },

    methods: {

        createCustomerAddressSelect() {
            let el = this.$refs['root'];
            $(el).find(".customer_address_select").html("");
            this.address_select = frappe.ui.form.make_control({
                parent: $(el).find(".customer_address_select"),
                df: {
                    fieldtype: "Link",
                    fieldname: "address_select",
                    label: "Delivery Address",
                    options: "Address",
                    onchange: () => {
                        if (this.address_select.get_value() && this.address_select.get_value() != "") {
                            this.packSlipData.address = this.address_select.get_value();
                            this.makeCustomerDisplayAddress(this.address_select.get_value());
                        } else {
                            this.packSlipData.deliver_to = "";
                        }
                    },
                    get_query: () => {
                        return {
                            filters: {
                                link_name: this.pickListData.customer
                            }
                        };
                    }
                },
                doc: this.sample_doc,
                render_input: true
            });
            this.setCustomerAddress();
        },

        makeCustomerDisplayAddress(address) {
            frappe.call({
                method: "essdee_sales.sales_order_management.doctype.customer.customer.get_customer_ps_deliver_to",
                args: {
                    address_dict: address
                },
                freeze: true,
                callback: (response) => {
                    this.packSlipData.deliver_to =  response.message;
                }
            });
        },

        setCustomerAddress() {
            if (this.packSlipData.address) {
                this.address_select.set_value(this.packSlipData.address);
            } else {
                this.getDefaultCustomerDetails();
            }
        },

        getDefaultCustomerDetails(){
            frappe.call({
                method : "essdee_sales.sales_order_management.doctype.order.order.get_customer_prefill_details",
                args : {
                    customer : this.packSlipData.customer
                },
                callback : (response) => {
                    if(!response.message.address) return;
                    this.address_select.set_value(response.message.address);
                }

            })
        },

        makeTransportSelect() {
            let el = this.$refs['root'];
            $(el).find(".transport-select").html("");
            this.transport_select = frappe.ui.form.make_control({
                parent: $(el).find(".transport-select"),
                df: {
                    fieldtype: "Link",
                    fieldname: "transport-select",
                    label: "Transport",
                    options: "Transport",
                    reqd: true,
                    onchange: () => {
                        if (this.transport_select.get_value() && this.transport_select.get_value() != "") {
                            this.packSlipData.transport = this.transport_select.get_value();
                        } else {
                            this.packSlipData.transport = null;
                        }
                    }
                },
                doc: this.sample_doc,
                render_input: true
            });
            this.setTransportSelect();
        },

        setTransportSelect() {
            if (this.packSlipData.transport && this.packSlipData.transport != "") {
                this.transport_select.set_value(this.packSlipData.transport);
            }
        },

        openItemAddDialog() {
            let dialog = new frappe.ui.Dialog({
                title: 'Add New Item',
                fields: [
                    {
                        label: 'Add Item Html',
                        fieldname: "add_item_html",
                        fieldtype: 'HTML'
                    }
                ],
                primary_action: () => {
                    let additionalData = JSON.parse(additionalItemUI.get_data());
                    additionalData.forEach((element) => {
                        element.forEach((ele) => {
                            ele.isSelected = true;
                            ele.order = "";
                            ele.carton = 0;
                            this.addPackListItem(ele);
                        });
                    });
                    dialog.hide();
                }
            });
            dialog.show();
            let additionalItemUI = new frappe.essdee_sales.OMS.ui.AdditionalOrderItem(
                dialog.fields_dict.add_item_html.wrapper, dialog
            );
            dialog.$wrapper.find('.modal-dialog').css("min-width", "max-content");
        },

        makePriceListSelect() {
            let el = this.$refs['root'];
            $(el).find(".ps_price_list").html("");
            this.price_list_select = frappe.ui.form.make_control({
                parent: $(el).find(".ps_price_list"),
                df: {
                    fieldtype: "Link",
                    fieldname: "price_list",
                    label: "Price List",
                    options: "Price List",
                    reqd: true,
                    onchange: () => {
                        if (this.price_list_select.doc.price_list && this.price_list_select.doc.price_list != "") {
                            this.packSlipData.price_list = this.price_list_select.doc.price_list;
                        } else {
                            this.packSlipData.price_list = null;
                        }
                    }
                },
                doc: this.sample_doc,
                render_input: true
            });
            this.setPriceList();
        },

        setPriceList() {
            if (this.packSlipData.price_list && this.packSlipData.price_list != "") {
                this.price_list_select.set_value(this.packSlipData.price_list);
            } else {
                this.setDefaultPriceList();
            }
        },

        setDefaultPriceList() {
            frappe.call({
                method: 'essdee_sales.sales_order_management.doctype.order_settings.order_settings.get_deafult_order_values',
                callback: (response) => {
                    if (response.message.status == 200) {
                        this.price_list_select.set_value(response.message.price_list);
                    }
                }
            });
        },

        makeSchemeMultiSelect() {
            frappe.model.with_doctype('Scheme Select', () => {
                let el = this.$refs['root'];
                $(el).find(".scheme-multiselect").html("");
                this.scheme_multi_select = frappe.ui.form.make_control({
                    parent: $(el).find(".scheme-multiselect"),
                    df: {
                        fieldname: "schemes_select",
                        fieldtype: "Table MultiSelect",
                        label: "Schemes (Optional)",
                        options: "Scheme Select",
                        onchange: () => {
                            this.packSlipData.schemes = this.scheme_multi_select.get_value();
                        },
                        get_query: () => {
                            return {
                                filters: [
                                    ["Scheme", "sales_partner", "=", this.packSlipData.sales_partner]
                                ]
                            };
                        }
                    },
                    doc: this.sample_doc,
                    render_input: true
                });
                if (this.packSlipData.schemes.length > 0) {
                    this.scheme_multi_select.doc.schemes_select = this.packSlipData.schemes;
                    this.scheme_multi_select.refresh();
                }
            });
        },

        makeDispatchLocationSelect(){
            let el = this.$refs['root'];
            $(el).find(".dispatch-select").html("");
            this.dispatch_location_select = frappe.ui.form.make_control({
                parent : $(el).find(".dispatch-select"),
                df : {
                    fieldname : 'dispath_location',
                    fieldtype : "Link",
                    label : "Dispatch From",
                    options : "Warehouse",
                    reqd: 1,
                    onchange : ()=>{
                        this.packSlipData.dispatch_location = this.dispatch_location_select.get_value();
                    }
                },
                doc: this.sample_doc,
                render_input: true
            })
            if (this.packSlipData.dispatch_location && this.packSlipData.dispatch_location != ""){
                this.dispatch_location_select.df.read_only = 1
                this.dispatch_location_select.doc.dispath_location = this.packSlipData.dispatch_location;
                this.dispatch_location_select.refresh();
            }
        },

        makePackingTeamSelect(){
            let el = this.$refs['root'];
            $(el).find(".packing-team-select").html("");
            this.packing_team = frappe.ui.form.make_control({
                parent : $(el).find(".packing-team-select"),
                df : {
                    fieldname : "packing_team",
                    fieldtype : "Link",
                    options : "Packing Team",
                    label : "Packing Team",
                    reqd : 1,
                    onchange : ()=>{
                        this.packSlipData.packing_team = this.packing_team.get_value();
                    }
                },
                doc : this.sample_doc,
                render_input : true
            });

            if (this.packSlipData.packing_team && this.packSlipData.packing_team != ""){
                this.packing_team.doc.packing_team = this.packSlipData.packing_team;
                this.packing_team.refresh();
            }
        },

    },
    mounted() {
        this.createCustomerAddressSelect();
        this.makeTransportSelect();
        this.makePriceListSelect();
        this.makeSchemeMultiSelect();
        this.makeDispatchLocationSelect();
        this.makePackingTeamSelect();
    }
};
</script>



<style scoped>

.flex_row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex_col{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.align_bottom{
    align-self: flex-end;
    margin-left: 1rem;
}

input{
    min-width: fit-content;
}

th,td{
    align-items: flex-start;
    width: max-content;
    padding: 3px;
}

td>input{
    align-self: center;
}

.heading{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.btn{
    max-width: max-content;
}

.pack-cus-details{
    padding: 5px;
    margin: 15px;
}

.panel-heading{
    font-size: 0.9rem;
    font-weight: 700;
    padding-bottom:2px ;
}

.gap-min{
    gap: 0.1rem;
}

.form-control-input{
    min-width: 40px !important;
}

.panel-content{
    background-color: rgb(255, 255, 255);
    padding: 4px;
    border-radius: 5px;
}

.form-min{
    max-width: 4rem;
}

.standard-instruction{
    border: 1px solid black;
    border-radius: 10px;
    padding: 1rem;
}

.unread {
    background-image : linear-gradient(to bottom,#f2dede 0,#e59191 100%)
}

.read{
    background-image: linear-gradient(to bottom,#dff0d8 0,#a8cf97 100%);
}

.comment-box{
    margin: 0%;
    width: 35vw;
    padding: 0.5rem;
    min-width: 500px;
    resize: none;
}

.main{
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.table-container{
    margin-top: 0.1rem;
}
.address-box{
    width: 100%;
    min-height: 100px;
    border: none;
    background-color: var(--bg-color);
}
</style>
<template>

    <div>
        <h4>Items For Cancel</h4>
        <table class="table table-bordered" v-if="requestItems.length!=0">
            <thead class="grid-heading-row">
                <th>Order Date</th>
                <th>Order</th>
                <th>Customer</th>
                <th>City</th>
                <th v-for="key in requestItems[0]['attributes']" :key="key">{{key}}</th>
                <template v-if="requestItems[0]['primary_attribute']">
                    <th v-for="key in requestItems[0]['primary_attribute_values']" :key="key">{{key}}</th>
                </template>
                <th v-else>Quantity</th>
                <th>Boxes</th>
                <th class="fixed">Multiplier</th>
                <th>Total Boxes</th>
            </thead>
            <tbody>
                <tr v-for="(item1) in requestItems" :key="item1">
                    <td>{{item1.order_date}}</td>
                    <td>{{item1.order}}</td>
                    <td>{{item1.customer}}</td>
                    <td>{{item1.city? item1.city : "--"}}</td>
                    <td v-for="key in requestItems[0]['attributes']" :key="key">{{item1['attribute_values'][key]}}</td>
                    <template v-if="item1['primary_attribute']">
                        <td v-for="key in requestItems[0]['primary_attribute_values']" :key="key">{{(item1['qty'][key] && item1['qty'][key] > 0)? item1['qty'][key] : '--'}}</td>
                    </template>
                    <td v-else>{{item1['qty']['default']}}</td>
                    <td>{{item1.tot}}</td>
                    <td>{{item1.multiplier}}</td>
                    <td>{{item1.tot_quan}}</td>
                </tr>
            </tbody>
        </table>
    </div>

</template>

<script>

export default {
    name : "MultiOrderCancelItemDisplay",
    props : ["items"],
    data() {
        return {
            requestItems : []
        }
    },
    methods : {
        makeTotalQuantities(orderItem) {
          if (orderItem.primary_attribute) {
            let tot = 0;
            for (let i = 0; i < orderItem.primary_attribute_values.length; i++) {
              let key = orderItem.primary_attribute_values[i];
              tot += Number(orderItem.qty[key]);
            }
            orderItem.tot = tot;
            orderItem.tot_quan = tot * Number(orderItem.multiplier);
          } else {
            orderItem.tot = orderItem.qty.default;
            orderItem.tot_quan = orderItem.tot * Number(orderItem.multiplier);
          }
        },
    },
    mounted(){
        for(let i=0;i<this.items.length;i++){

            this.requestItems.push(JSON.parse(JSON.stringify(this.items[i])));
            this.makeTotalQuantities(this.requestItems[i]);
        }
    }
}
</script>

<style scoped>
table {
    width: 100%;
}
</style>
<template>
    <div class="container">
        <div v-if="reportData.display" class="table-responsive">
    	    <input type="text" @input="changeQuery" class="form-control" id="search-filter" placeholder="Start typing to filter">
            <table class="table table-bordered" v-if="reportData['displayData'].length!=0">
                <thead class="grid-heading-row">
                    <tr>
                        <th>Customer</th>
                        <th>City</th>
                        <th v-for="key in reportData['displayData'][0]['attributes']" :key="key">{{key}}</th>
                        <template v-if="reportData['displayData'][0]['primary_attribute']">
							<th v-for="pri in reportData['displayData'][0]['primary_attribute_values']" :key="pri">{{pri}}</th>
                        </template>
                        <template v-else>
                            <th>Quantity</th>
                        </template>
                        <th>Total Boxes</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item1) in reportData['displayData']" :key="item1">
                        <td>{{item1.customer}}</td>
                        <td>{{item1.city? item1.city : "--"}}</td>
                        <td v-for="key in reportData['displayData'][0]['attributes']" :key="key">{{item1['attribute_values'][key]}}</td> 
                        <template v-if="reportData['displayData'][0]['primary_attribute']">
							<td v-for="pri in reportData['displayData'][0]['primary_attribute_values']" :key="pri">{{ item1['qty'][pri] && item1['qty'][pri] > 0? item1['qty'][pri] : "--"}}</td>
                        </template>
                        <template v-else>
                            <td>{{item1['qty']['default']}}</td>
                        </template>
                        <td>{{item1['total_boxes']}}</td>
                    </tr>
                    <tr>
                        <td :colspan="2+(reportData['displayData'][0]['attributes']).length" style="text-align:right;">Grand Total</td>
                        <template v-if="reportData['displayData'][0]['primary_attribute']">
							<td v-for="pri in reportData['displayData'][0]['primary_attribute_values']" :key="pri">{{reportData['totalData'][pri]? reportData['totalData'][pri] : "--" }}</td>
                        </template>
                        <td v-else>{{reportData['totalData']['tot']}}</td> 
                        <td>{{reportData['totalData']['gr_tot']}}</td>
                    </tr>
                </tbody>
            </table>
            <div v-else>
                <nothing-to-display/>
            </div>
        </div>
        <nothing-to-display v-else/>
    </div>
</template>

<script>
import NothingToDisplay from './NothingToDisplay.vue';
import { isMatchInData } from '../Utils/filters';

export default {

  name: 'CustomerwisePackingSlipReport',

  props: {
    item_name: String,
    from_date: String,
    to_date: String,
    sales_partner: String,
    customer: String
  },

  components: {
    NothingToDisplay
  },

  data() {

    return {
      reportData: {
        data: [],
        display: false,
        displayData: [],
        totalData: {}
      },
      searchQuery: ''
    };

  },

  methods: {

    changeQuery(event) {
      this.searchQuery = event.target.value;
      this.makeQueryDisplayData();
    },

    makeQueryDisplayData() {
      this.reportData.displayData = [];
      for (let i = 0; i < this.reportData.data.length; i++) {
        if (this.searchQuery === '') {
          this.reportData.displayData.push(this.reportData.data[i]);
        } else {
          if (isMatchInData(this.searchQuery, this.reportData.data[i])) {
            this.reportData.displayData.push(this.reportData.data[i]);
          }
        }
      }
    },

    addItem(newItem) {
      this.reportData.data.push(newItem);
    },

    makeDisplayableData(data) {
      let total = 0;
      for (let i = 0; i < data.length; i++) {
        let item_data = data[i];
        let temp_tot = this.getTotalBoxes(item_data);
        item_data.total_boxes = temp_tot;
        total += temp_tot;
        this.addItem(item_data);
      }
      this.reportData.totalData.gr_tot = total;
      this.sortBasedOnCustomer();
    },

    getTotalBoxes(obj) {
      if (!obj.primary_attribute) {
        if (!this.reportData.totalData.tot) {
          this.reportData.totalData.tot = 0;
        }
        let temp_tot = Number(obj.qty.default);
        this.reportData.totalData.tot += temp_tot;
        return temp_tot;
      }
      let total = 0;
      for (let i = 0; i < obj.primary_attribute_values.length; i++) {
        let key = obj.primary_attribute_values[i];
        let temp_tot = Number(obj.qty[key]);
        total += temp_tot;
        if (!this.reportData.totalData[key]) {
          this.reportData.totalData[key] = 0;
        }
        this.reportData.totalData[key] += temp_tot;
      }
      return total;
    },

    sortBasedOnCustomer() {
      this.reportData.data.sort((a, b) => a.customer.localeCompare(b.customer));
      this.makeQueryDisplayData();
    },

    fetchDisplayData() {
      if (!this.item_name) {
        return;
      }
      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.packing_slip.packing_slip.get_item_wise_packed_report',
        args: {
          item: this.item_name,
          start_date: this.from_date,
          end_date: this.to_date,
          sales_partner: this.sales_partner,
          customer: this.customer,
        },
		freeze : true,
		freeze_message : "Fetching Details",
        callback: (response) => {
          response = response.message;
          if (response.status === 200) {
            this.makeDisplayableData(response.data);
            this.reportData.display = true;
          }
        }
      });
    }

  },

  mounted() {
    this.fetchDisplayData();
  }
  
};
</script>

<style scoped>
table {
  width: 100%;
}
</style>
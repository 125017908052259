<template>
<div ref="root">
  <div class="table-responsive">
      <template v-if="!canEdit() && itemDisplayData.length!=0">
          <h4>Pending Items</h4>
          <hr style="height : 2px">
      </template>
      <table v-if="itemDisplayData.length!=0">
          <tr v-for="(items,index1) in itemDisplayData" :key="items"  >
              <table class="table table-bordered" v-if="items.length!=0">
                  <thead class="grid-heading-row heading-row">
                      <tr>
                          <th>Item Name</th>
                          <th v-for="sec in items[0]['attributes']" :key="sec">{{sec}}</th>
                          <template v-if="items[0].primary_attribute">
                              <th v-for="pri in items[0]['primary_attribute_values']" :key="pri">{{pri}}</th>
                          </template>
                          <th>Comments</th>
                          <template v-if="!items[0]['primary_attribute']">
                              <th>Quantity</th>
                          </template>
                          <th class="fixed">Multiplier</th>
                          <th class="actions" v-if="canEdit()">Actions</th>
                          <th v-else class="fixed">Picked</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(item1,index2) in items" :key="item1">
                          <td>{{item1.item_name}}</td>
                          <td v-for="sec in items[0]['attributes']" :key="sec">{{item1['attribute_values'][sec]}}</td>
                          <template v-if="item1['primary_attribute']">
                              <td v-for="pri in items[0]['primary_attribute_values']" :key="pri">{{(item1['qty'][pri] && item1['qty'][pri]!=0)? item1['qty'][pri] : '--'}}</td>
                          </template>
                          <td>{{item1.comment}}</td>
                          <template v-if="!item1['primary_attribute']">
                              <td>{{item1['qty']['default']}}</td>
                          </template>
                          <td>{{item1['multiplier']}}</td>
                          <td class="flex_row" v-if="canEdit()">
                              <span v-html="frappe.utils.icon('delete', 'md')" class="cursor" @click="removeOrderItem(index1,index2)"></span>
                              <span v-html="frappe.utils.icon('edit', 'md')" class="cursor" @click="editOrderItem(index1,index2)"></span>
                          </td>
                          <td v-else>
                              <div class="ml-5 cursor" v-if="!canEdit()">
                                  <input type="checkbox" v-model="item1['isPickListed']">
                              </div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </tr>
      </table>
      <template v-if="!canEdit()">
          <button class="btn btn-success" v-if="itemDisplayData.length!=0" @click.prevent="makePickList()">Make Picklist</button>
          <original-cancel-order-item-vue :order="getOrderName()"/>
      </template>
  </div>
  <div v-show="canEdit()">
      <div class="item_select col-md-5"></div>
  </div>
  <div v-if="itemCreationData.display" class="table-responsive rounded-3">
      <h5>New Item</h5>
      <table class="table table-bordered" id="input_table">
          <thead>
              <tr class="grid-heading-row">
                  <th>Item Name</th>
                  <th v-for="sec_val in itemCreationData.item_details['attributes']" :key="sec_val">{{sec_val}}</th>
                  <template v-if="itemCreationData.item_details['primary_attribute']">
				  	<th v-for="pri_val in itemCreationData.item_details['attribute_values'][itemCreationData.item_details['primary_attribute']]" :key="pri_val">{{pri_val}}</th>
                  </template>
                  <th>Comments</th>
                  <th v-if="!itemCreationData.item_details['primary_attribute']">Quantity</th>
                  <th class="fixed">Multiplier</th>
              </tr>
          </thead>
          <tbody>
              <tr>
                  <td>{{ itemCreationData.item_name }}</td>
                  <td v-for="sec_val in itemCreationData.item_details['attributes']" :key="sec_val" >
                      <select name="attr_select" class="form-control" v-model="newItemDetails.data['attribute_values'][sec_val]" :id="'input_'+sec_val">
                          <option :value="null" selected>Select a {{ sec_val }}</option>
                          <option :value="value" v-for="value in itemCreationData.item_details['attribute_values'][sec_val]" :key="value">
                          {{ value }}
                          </option>
                      </select>
                  </td>
                  <td v-for="pri_val in itemCreationData.item_details['attribute_values'][itemCreationData.item_details['primary_attribute']]" :key="pri_val"> 
                      <input class="form-control editable-input" type="number" v-model="newItemDetails.data['qty'][pri_val]" :id="'input_'+pri_val"/>
                  </td>
                  <td>
                      <input class="form-control" type="text" v-model="newItemDetails.data.comment">
                  </td>
                  <td v-if="!itemCreationData.item_details['primary_attribute']">
                      <input class="form-control" type="number" v-model="newItemDetails.data['qty']['default']" :id="'input_qty'"/>
                  </td>
                  <td>
                      <input class="form-control" type="number" v-model="newItemDetails.data.multiplier" :id="'input_multiplier'"/>
                  </td>
              </tr>
          </tbody>
      </table>
      <button class="btn-success border-none rounded mb-3" @click.prevent="addItemToTable()">
          Add Item
      </button>
  </div>
  <div class="table-responsive" v-if="itemEditData.display">
      <h5>Item Update</h5>
      <table class="table table-bordered" id="input_table">
          <thead class="grid-heading-row">
            	<th>Item Name</th>
            	<th v-for="sec_val in itemEditData.item_details['attributes']" :key="sec_val">{{sec_val}}</th>
            	<template v-if="itemEditData.item_details['primary_attribute']">
					<th v-for="pri_val in itemEditData.item_details['attribute_values'][itemEditData.item_details['primary_attribute']]" :key="pri_val">{{pri_val}}</th>
            	</template>
            	<th>Comments</th>
            	<th v-if="!itemEditData.item_details['primary_attribute']">Quantity</th>
            	<th class="fixed">Multiplier</th>
          </thead>
          <tbody>
               <tr>
                  <td>{{ itemEditData.item_name }}</td>
                  <td v-for="sec_val in itemEditData.item_details['attributes']" :key="sec_val" >
                      <select name="attr_select" class="form-control" v-model="editItemDetails.data['attribute_values'][sec_val]" :id="'input_'+sec_val">
                          <option :value="null">Select a {{ sec_val }}</option>
                          <option :value="value" v-for="value in itemEditData.item_details['attribute_values'][sec_val] " :key="value">
                          {{ value }}
                          </option>
                      </select>
                  </td>
                  <template v-if="itemEditData.item_details['primary_attribute']">
				  	<td v-for="pri_val in itemEditData.item_details['attribute_values'][itemEditData.item_details['primary_attribute']]" :key="pri_val">
                      <input class="form-control editable-input" type="number" v-model="editItemDetails.data['qty'][pri_val]" :id="'input_'+pri_val"/>
                  	</td>
				  </template>
                  <td>
                      <input class="form-control" type="text" v-model="editItemDetails.data.comment">
                  </td>
                  <td v-if="!itemEditData.item_details['primary_attribute']">
                      <input class="form-control" type="number" v-model="editItemDetails.data['qty']['default']" id="input_qty"/>
                  </td>
                  <td>
                      <input class="form-control" type="number" v-model="editItemDetails.data.multiplier" id="input_multiplier"/>
                  </td>
              </tr>
          </tbody>
      </table>
      <button class="btn btn-success mb-3" @click.prevent="makeUpdate()">Update</button>
      <button class="btn btn-secondary mb-3 ml-3" @click.prevent="cancelUpdate()">Cancel</button>
  </div>
</div>
</template>

<script>
import OriginalCancelOrderItemVue from "./OriginalCancelOrderItem.vue";
import { combineTableData, cloneData, validateItemData } from "../Utils";

export default {
  name: "OrderItem",

  components: {
    OriginalCancelOrderItemVue,
  },

  props: {
    order_details: {
      type: Array,
      required: true,
    },
  },

  data() {

    return {

      itemCreationData: {
        display: false,
        item_name: "",
        item_details: {},
      },
	  
      newItemDetails: {
        data: {},
      },

      itemDisplayData: [],
      sizeTypeItemsDisplayData : [],

      itemEditData: {
        display: false,
        item_name: "",
        item_details: {},
      },

      editItemDetails: {
        data: {},
        ind1: null,
        ind2: null,
      },

      pickListData: {
        data: [],
      },

      item_select: null,
      sample_doc: {},

    };
  },

  methods: {

    addItem(newItem) {
      combineTableData(newItem, this.itemDisplayData);
      if (this.item_select) {
        this.item_select.set_value(null);
      }
    },

    createItemCreationField(item_name) {
      this.itemCreationData.display = false;
      this.itemCreationData.item_name = "";
      this.itemCreationData.item_details = {};
      this.newItemDetails.data = {};
      if (item_name === "") {
        return;
      }
      this.editOrderItem(null, null, true);
      frappe.call({
        method:
          "essdee_sales.sales_order_management.doctype.item.item.get_item_details",
        args: { item_name : item_name, fetch_size_type_data : true },
        callback: (response) => {
          response = response.message;
          if (response.status === 200) {
            this.itemCreationData.display = true;
            this.itemCreationData.item_name = item_name;
            this.itemCreationData.item_details = response.data;
            this.makeItemCreationData(response.data);
          }
        },
      });
    },

    makeItemCreationData(data) {
      this.newItemDetails.data["item_name"] = data.item_name;
      this.newItemDetails.data["primary_attribute"] = null;
      this.newItemDetails.data["primary_attribute_values"] = [];
      this.newItemDetails.data["qty"] = {};
      this.newItemDetails.data["attributes"] = [];
      this.newItemDetails.data["attribute_values"] = {};

      if (data["primary_attribute"]) {
        let prim = data["primary_attribute"];
        data["attribute_values"][prim].forEach((element) => {
          this.newItemDetails.data["qty"][element] = 0;
          this.newItemDetails.data["primary_attribute_values"].push(element);
        });
        this.newItemDetails.data["primary_attribute"] = prim;
      }

      data["attributes"].forEach((ele) => {
        this.newItemDetails.data["attributes"].push(ele);
        this.newItemDetails.data["attribute_values"][ele] = null;
      });

      this.newItemDetails.data["qty"]["default"] = 0;
      this.newItemDetails.data.comment = "";
      this.newItemDetails.data.multiplier = 1;
    },

    addItemToTable() {
      let tempData = this.newItemDetails.data;
      if (!validateItemData(tempData,'input_')) return;
      this.addItem(tempData);
      this.makeDirty();
    },

    makeDirty() {
      cur_frm.dirty();
    },

    editOrderItem(index1, index2, clear_update) {
      this.itemEditData.display = false;
      this.itemEditData.item_name = "";
      this.itemEditData.item_details = {};
      this.editItemDetails.data = {};
      if (clear_update) {
        return;
      }
      let item_name = this.itemDisplayData[index1][index2].item_name;
      if (item_name === "") {
        return;
      }
      if (this.item_select) {
        this.item_select.set_value(null);
      }
      frappe.call({
        method:
          "essdee_sales.sales_order_management.doctype.item.item.get_item_details",
        args: { item_name },
        callback: (response) => {
          response = response.message;
          if (response.status === 200) {
            this.itemEditData.item_name = item_name;
            this.itemEditData.item_details = response.data;
            this.editItemDetails.data = cloneData(
              this.itemDisplayData[index1][index2]
            );
            this.editItemDetails.ind1 = index1;
            this.editItemDetails.ind2 = index2;
            this.itemEditData.display = true;
          }
        },
      });
    },

    removeOrderItem(index1, index2) {
      let items = this.itemDisplayData[index1];
      if (index2 >= 0 && index2 < items.length) {
        items.splice(index2, 1);
        this.makeDirty();
        if (items.length === 0) {
          this.itemDisplayData.splice(index1, 1);
        }
      }
    },

    cancelUpdate() {
      this.editItemDetails.data = {};
      this.editItemDetails.ind1 = null;
      this.editItemDetails.ind2 = null;
      this.itemEditData.display = false;
      this.itemEditData.item_name = "";
      this.itemEditData.item_details = {};
    },

    get_data() {
      return JSON.stringify(this.itemDisplayData);
    },

    makeSelectField() {
      let el = this.$refs["root"];
      $(el).find(".item_select").html("");
      this.item_select = frappe.ui.form.make_control({
        parent: $(el).find(".item_select"),
        df: {
          fieldtype: "Link",
          fieldname: "item_select",
          label: "Select Item",
          options: "Item",
          onchange: () => {
            if (this.item_select["last_value"] !== this.item_select["value"]) {
              this.createItemCreationField(this.item_select.get_value());
            }
          },
        },
        doc: this.sample_doc,
        render_input: true,
      });
    },

    makeSortedList() {
      this.itemDisplayData.sort((a, b) =>
        a[0].item_name.localeCompare(b[0].item_name)
      );
    },

    makeUpdate() {
      if (!validateItemData(this.editItemDetails.data,'input_')) return;
      this.itemDisplayData[this.editItemDetails.ind1][
        this.editItemDetails.ind2
      ] = cloneData(this.editItemDetails.data);
      this.cancelUpdate();
      this.makeDirty();
    },

    canEdit() {
      return cur_frm.doc.docstatus !== 1;
    },

    makeSelectItem(ind1, ind2, event) {
      if (event) {
        this.pickListData.data.push({
          row: ind1,
          col: ind2,
        });
        return;
      } else {
        for (let i = 0; i < this.pickListData.data.length; i++) {
          if (
            this.pickListData.data[i].row === ind1 &&
            this.pickListData.data[i].col === ind2
          ) {
            this.pickListData.data.splice(i, 1);
            break;
          }
        }
      }
    },

    makePickList() {
      let data = [];
      for (let i = 0; i < this.itemDisplayData.length; i++) {
        for (let j = 0; j < this.itemDisplayData[i].length; j++) {
          data.push(this.itemDisplayData[i][j]);
        }
      }
      frappe.call({
        method:
          "essdee_sales.sales_order_management.doctype.order.order.make_pick_list",
        args: {
          pick_list_data: JSON.stringify({
            order: cur_frm.doc.name,
            pick_list: data,
          }),
        },
        callback: (response) => {
          response = response.message;
          if (response.status === 200) {
            frappe.show_alert({
				message : "Pick List Updated",
				indicator : "Green"
			},5);
            cur_frm.reload_doc();
          }
        },
      });
    },
    getOrderName() {
      return cur_frm.doc.name;
    },
  },

  mounted() {
    if (this.canEdit()) {
      this.makeSelectField();
    }

    this.order_details.forEach((ele) => {
      this.addItem(ele);
    });

    this.makeSortedList();
  },

};

</script>

<style scoped>

input {
  max-width: 5rem;
  align-self: center;
}

table{
    width: 100%;
}

.cursor{
    cursor: pointer;
}

.bg_unable{
    background-color: rgb(187,222,251);
    color: black;
}

h4{
    padding: 1rem;
    padding-bottom: 0%;
    border-radius: 5rem;
    max-width: max-content;
}

.flex_row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

</style>
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  class: "address-box",
  style: {"max-width":"250px","min-width":"250px"}
}
const _hoisted_4 = { class: "d-flex justify-between" }
const _hoisted_5 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.attrList, (attr) => {
        return (_openBlock(), _createElementBlock("div", {
          key: attr,
          class: "col"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h5", null, _toDisplayString(attr.attribute_name), 1 /* TEXT */),
              (attr.attr_value_link)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    onClick: _withModifiers($event => (
                $options.openItemAttribute(attr.doctype, attr.attr_value_link)
              ), ["prevent"]),
                    class: "btn btn-default"
                  }, " Edit ", 8 /* PROPS */, _hoisted_5))
                : _createCommentVNode("v-if", true)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attr.attr_values, (attr_value) => {
              return (_openBlock(), _createElementBlock("p", { key: attr_value }, _toDisplayString(attr_value.attribute_value), 1 /* TEXT */))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}
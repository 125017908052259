<template>
<div class="main" ref="root">
    <div class="container flex_col" v-show="packSlipData.display">
        <div class="flex_col gap-min">
            <h4>Essdee Knitting Mills Private Limited</h4>
            <p>Tirupur, TamilNadu.</p>
        </div>
        <h5>Packing Slip</h5> 
        <div class="flex_row" style="margin: 0; height: 100px;">
            <div>
                Deliver To: 
                <textarea disabled class="address-box" rows="4" style=" resize: none;">{{ packSlipData.data['deliver_to'] }}</textarea>
            </div>
            <div style="display: flex; flex-direction: row; gap: 5px; width: max-content;">
                <div disabled style="resize: none; text-wrap: nowrap; text-align: right;" class="address-box">
                    Packing Slip:<br> Date:<br> Transport:<br> Price List:<br>
                </div>
                <div disabled style="resize: none; text-wrap: nowrap; text-align: left;" class="address-box">
                    {{packSlipData.data['pack_slip']+""+(Number(packSlipData.data['revision'])>0? ` \{rev-${packSlipData.data['revision']}\}` : "")}}<br>
                    {{frappe.format(packSlipData.data['created_date_time'], { fieldtype: 'Date' })}}<br>
                    {{packSlipData.data['transport']? packSlipData.data['transport'] : packSlipData.data['transport_1']}}<br>
                    {{packSlipData.data['price_list']}}<br>
                </div>
            </div>
        </div>
        <div class="flex_col" style="margin: 0;">
            <table class="table table-bordered"  v-if="render_ready">
                <tr v-for="(items,index1) in packSlipData.data['array_data']" :key="items"  >
                    <table class="table table-bordered" v-if="items.length!=0" style="margin:0px;">
                        <thead class="grid-heading-row">
                            <tr>
                                <th class="sno">S.No</th>
                                <th>Item Name</th>
                                <th v-for="key in items[0]['attributes']" :key="key">{{key}}</th>
                                <template v-if="items[0]['primary_attribute']">
                                    <th v-for="key in items[0]['primary_attribute_values']" :key="key">{{key}}</th>
                                </template>
                                <template v-if="!items[0]['primary_attribute']">
                                    <th>Quantity</th>
                                </template>
                                <th class="fixed">Boxes</th>
                                <th class="fixed">Multiplier</th>
                                <th class="fixed">Carton</th>
                                <th class="fixed">Total Boxes</th>
                                <th class="fixed">Scheme</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item1,index2) in items" :key="item1" >
                                <td>{{index2+1}}</td>
                                <td>{{item1.item_name}}</td>
                                <td v-for="key in items[0]['attributes']" :key="key">
                                    {{item1['attribute_values'][key]}}
                                </td>
                                <template v-if="item1['primary_attribute']">
                                    <td v-for="key in items[0]['primary_attribute_values']" :key="key">
                                        {{(item1['qty'][key] && item1['qty'][key] != 0) ? item1['qty'][key] : '--'}}
                                    </td>
                                </template>
                                <template v-if="!item1['primary_attribute']">
                                    <td>
                                        {{item1['qty']['default']}}
                                    </td>
                                </template>
                                <td>{{item1.tot_box}}</td>
                                <td>
                                    {{item1.multiplier}}
                                </td>
                                <td>{{item1.carton? item1.carton : '--'}}</td>
                                <td>{{item1.tot_box * item1.multiplier}}</td>
                                <td>{{item1.scheme}}</td>
                            </tr>
                            <tr v-if="index1 == packSlipData.data['array_data'].length-1">
                                <td :colspan="second_colspan+first_colspan">{{( packSlipData['data']['schemes'] && packSlipData['data']['schemes'].length!= 0) ? get_scheme_list(packSlipData['data']['schemes']) : ""}}</td>
                                <td colspan="3" style="text-align:right">Grand Total:</td>
                                <td class="fixed">{{packSlipData['data']['no_of_carton']}}</td>
                                <td class="fixed">{{packSlipData['data']['total_boxes']}}</td>
                            </tr>
                            <tr v-if="packSlipData['data']['comments'] && packSlipData['data']['comments']!= '' && index1 == packSlipData.data['array_data'].length-1">
                                <td colspan="4">Packing Slip Comments:</td>
                                <td style="text-align: center" :colspan="total_colspan+2">{{packSlipData['data']['comments']}}</td>
                            </tr>
                            <tr v-if="index1 == packSlipData.data['array_data'].length-1">
                                <td :colspan="equal_col">Checked By:</td>
                                <td :colspan="packedby_col">Packed By: {{ packSlipData['data']['packedby'] }}</td>
                                <td :colspan="equal_col+1">Dispatched From : {{packSlipData['data']['dispatch_from']}}</td>
                            </tr>
                        </tbody>
                    </table>
                </tr>
            </table>
        </div>
        <div class="addtional_content">
            <div>
                <label for="packListCartonCheck">Disable Carton Slip:</label>
                <input type="checkbox" id="packListCartonCheck" class="form-control" v-model="packSlipData['data']['disable_carton_slip']" disabled>
            </div>
            <div v-if="packSlipData['data']['is_cancelled']">
                <label for="cancelReason">Cancel Reason :</label>
                <textarea class="address-box" v-model="packSlipData['data']['cancel_reason']" disabled>
                </textarea>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'PackingSlipReport',

    props: {
        pack_slip_details: {
            type: Object,
            required: true
        }
    },

    data() {

        return {
            packSlipData: {
                data: {},
                display: false
            },
            root: null,
            sample_doc: {},
            address_template: null,
            schemes_select: null,
            first_colspan : 0,
            second_colspan : 0,
            render_ready : false,
            packedby_col : 0,
            equal_col : 0,
            total_colspan : 0,
        };

    },
    methods: {

        setPropsData(data) {
            this.packSlipData.data = data;
            this.makeTotalsCalculations();
            this.packSlipData.display = true;
        },

        makeTotalsCalculations(){
            let total_boxes = 0;
            let total_cartons = 0;
            let local_data = this.packSlipData.data['array_data'];
            for(let i=0;i< local_data.length; i++){
                for(let k =0;k<local_data[i].length;k++){
                     if (local_data[i][k]['primary_attribute']){
                        let local= 0;
                        for(let j=0;j<local_data[i][k]['primary_attribute_values'].length;j++){
                            let key = local_data[i][k]['primary_attribute_values'][j];
                            local += Number(local_data[i][k]['qty'][key]);
                        }
                        local_data[i][k]['tot_box'] = local ;
                        total_boxes +=local * Number(local_data[i][k]['multiplier']);
                    }
                    else {
                        local_data[i][j]['tot_box'] = Number(local_data[i][k]['qty']['default']);
                        total_boxes += Number(local_data[i][k]['qty']['default']) * Number(local_data[i][k]['multiplier']);
                    }
                    total_cartons += local_data[i][k]['carton'];
                    if(!local_data[i][k]['scheme']){
                        local_data[i][k]['scheme'] = 0;
                    }
                }
            }
            this.packSlipData.data['no_of_carton'] = total_cartons;
            this.packSlipData.data['total_boxes'] = total_boxes;
        },

        get_scheme_list(schemes){
            let schemeString = "";
            for(let i = 0;i<schemes.length;i++){
                schemeString += schemes[i]['scheme'];
                if(schemes[i]['amt'] > 0){
                    schemeString+= ":"+schemes[i]['amt'];
                }
                if(i!=schemes.length -1){
                    schemeString += ",  "
                }
            }
            return schemeString;
        },

        calculateColspan(){
            let i = this.packSlipData.data['array_data'].length;
            if(i == 0){
                frappe.throw("Packing Slip Requires Atleast One Item");
                return;
            }
          
            let last = this.packSlipData['data']['array_data'][i-1][0];
            let total_col = 1 + last['primary_attribute_values'].length + last['attributes'].length;
            let single_percent = Math.floor((total_col+5) /10);
            let tri_percent = single_percent*3;
            this.packedby_col = (total_col+5) - (2* tri_percent);
            this.equal_col = tri_percent;
            this.first_colspan = Math.ceil(total_col/2);
            this.second_colspan = total_col - this.first_colspan;
            this.render_ready = true;
            this.total_colspan = total_col;
        }
    },

    mounted() {
        this.setPropsData(this.pack_slip_details);
        // this.makeAddressHTML();
        this.calculateColspan();
    }
};
</script>


<style scoped>
*{
    margin: 0;
    padding: 0;
}
hr{
    height: 2px;
}
td{
    width: fit-content;
}
tr{
    margin: 0;
    padding: 0;
    width: 100%;
}
.flex_row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.align_start{
    align-items: flex-start;
}

.flex_col{
    display: flex;
    flex-direction: column;
    gap: 1px;
    align-items: center;
}

table{
    width: 100%;
}
.gap-min{
    gap: 2px;
    width: 100%;
}

.margined_div{
    margin-left: 1rem;
}

.address-box{
    width: 100%;
    /* min-height: fit-content; */
    border: none;
    background-color: var(--bg-color);
}

.addtional_content{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.container{
    margin: auto;
}
</style>
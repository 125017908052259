<template>
  <packing-slip-create-update :pickListData="pickListData" :packSlipData="packSlipData" :addToPackList="addToPackList"
    :removeFromPackList="removeFromPackList" :addPackListItem="addPackListItem" :finishPackingSlip="finishPackingSlip"
    :clearPackingSlip="clearPackingSlip" :makeInstructionRead="makeInstructionRead" :action="'edit'"
    v-if="packSlipData.display" />
</template>

<script>
import { cloneData, combineTableData, validatePackingSlip } from '../Utils'
import PackingSlipCreateUpdate from '../Components/PackingSlipCreateUpdate.vue';

export default {
  components: { PackingSlipCreateUpdate },
  name: "PackSlipEdit",
  props: ['ps_id'],

  data() {
    return {
      pickListData: {
        data: {},
        display: false,
        displayableData: {}
      },
      packSlipData: {
        data: {},
        display: false,
        packItems: [],
        displayableData: [],
        transport: "",
        packSlipComment: "",
        disableCarton: 0,
        existingData: [],
        standing_instructions_display: false,
        instruction_read: false,
        price_list: "",
        schemes: [],
        dispatch_location: "",
        packing_team: null,
        deliver_to: null,
        order_type: "Primary",
      }
    };
  },

  methods: {
    makeDisplayableData() {
      let customer = this.packSlipData.customer;
      this.pickListData.customer = customer;
      let temp_data = Object.keys(this.pickListData.data);
      if (temp_data.length === 0) {
        this.pickListData.display = true;
        return;
      }

      Object.keys(this.pickListData.data[customer]).forEach(keys => {
        this.pickListData.data[customer][keys].forEach((ele) => {
          ele['isSelected'] = false;
          ele['is_new'] = true;
          if (!this.pickListData.displayableData[ele['order']]) {
            this.pickListData.displayableData[ele['order']] = [];
          }
          combineTableData(ele, this.pickListData.displayableData[ele['order']]);
        });
      });
      this.pickListData.display = true;
    },

    makeEditablePackSlip(data) {
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          data[i][j]['packed'] = true;
          if (!data[i][j]['carton']) {
            data[i][j]['carton'] = 0;
          }
        }
      }
      this.packSlipData.displayableData = data;
      this.packSlipData.packItems = cloneData(this.packSlipData.displayableData);
    },

    orderWiseSeparation(data) {
      let temp_data = JSON.parse(JSON.stringify(data));
      data = {};
      for (let i = 0; i < temp_data.length; i++) {
        if (!data[temp_data[i]['customer']]) {
          data[temp_data[i]['customer']] = {};
        }
        if (!data[temp_data[i]['customer']][temp_data[i]['order']]) {
          data[temp_data[i]['customer']][temp_data[i]['order']] = [];
        }
        data[temp_data[i]['customer']][temp_data[i]['order']].push(temp_data[i]);
      }
      return data;
    },

    getPackingSlipData(packSlipId) {
      if (!packSlipId || packSlipId === "") {
        frappe.msgprint("Invalid Page");
        frappe.set_route('List', "Packing Slip");
        return;
      }
      frappe.call({
        method: "essdee_sales.sales_order_management.doctype.packing_slip.packing_slip.get_edit_pack_slip",
        args: {
          'ps_id': packSlipId
        },
        freeze: true,
        callback: (response) => {
          response = response.message;
          if (response.status === 200) {
            console.log(response)
            this.pickListData.data = this.orderWiseSeparation(response['slip_data']);
            this.packSlipData.existingData = response['selected_data'];
            this.packSlipData.customer = response['customer'];
            this.packSlipData.address = response['delivery_address'];
            this.packSlipData.sales_partner = response['sales_partner'];
            this.packSlipData.packSlipComment = response['packing_slip_comments'];
            this.packSlipData.transport = response['transport'];
            this.packSlipData.disableCarton = response['disable_carton_slip'] == 1 ? true : false;
            this.packSlipData.standing_instructions = response['standing_instruction'];
            this.packSlipData.standing_instructions_display = response['standing_instruction_display'];
            this.packSlipData.createdAt = response['created_at'];
            this.packSlipData.schemes = response['schemes'];
            this.packSlipData.dispatch_location = response['dispatch_location'];
            this.packSlipData.packing_team = response['packing_team'];
            this.packSlipData.deliver_to = response['deliver_to'];
            this.packSlipData.price_list = response['price_list'];
            this.makeEditablePackSlip(response['selected_data']);
            this.makeDisplayableData();
            this.packSlipData.display = true;
          } else {
            this.removeUnloadEvent();
            frappe.msgprint(response.data);
            frappe.set_route('List', 'Packing Slip');
          }
        }
      })
    },

    removeFromPackList(index1, index2) {
      let item = this.packSlipData.displayableData[index1][index2];
      item['isSelected'] = false;
      if (!item['is_new'] && this.packSlipData.packItems[index1][index2]['order'] && this.packSlipData.packItems[index1][index2]['order'] !== '') {
        if (!this.findMatchableData(item)) {
          combineTableData(item, this.packSlipData.packItems);
        }
      }

      this.packSlipData.packItems[index1].splice(index2, 1);
      this.packSlipData.displayableData[index1].splice(index2, 1);
      if (this.packSlipData.packItems[index1].length === 0) {
        this.packSlipData.packItems.splice(index1, 1);
        this.packSlipData.displayableData.splice(index1, 1);
      }
    },

    beforeUnloadListener(event) {
      event.preventDefault();
    },

    addUnloadEvent() {
      addEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
    },

    removeUnloadEvent() {
      removeEventListener("beforeunload", this.beforeUnloadListener, { capture: false });
    },

    findMatchableData(packListItem) {
      let keys = Object.keys(this.pickListData.displayableData);
      let found = false;
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === packListItem['order']) {
          let arrayList = this.pickListData.displayableData[keys[i]];
          for (let j = 0; j < arrayList.length; j++) {
            for (let k = 0; k < arrayList[j].length; k++) {
              if (arrayList[j][k]['col'] === packListItem['col'] && arrayList[j][k]['row'] === packListItem['row'] && arrayList[j][k]['order'] === packListItem['order']) {
                found = true;
                let changeData = arrayList[j][k];
                // The Multiplier Logic Needs to be done
                if (changeData['primary_attribute']) {
                  changeData['primary_attribute_values'].forEach(element => {
                    let value1 = changeData['qty'][element];
                    let value2 = packListItem['qty'][element];
                    changeData['qty'][element] = ((value1 || value1 !== "") ? Number(value1) : 0) + ((value2 || value2 !== "") ? Number(value2) : 0)
                  });
                } else {
                  changeData['qty']['default'] = Number(changeData['qty']['default']) + Number(packListItem['qty']['default']);
                }
                return true;
              }
            }
          }
        }
      }
      return found;
    },

    addToPackList(item) {
      item['isSelected'] = true;
      item['carton'] = 0;
      item['scheme'] = 0;
      this.addPackListItem(item);
    },

    addPackListItem(newItem) {
      combineTableData(newItem, this.packSlipData.displayableData);
      combineTableData(cloneData(newItem), this.packSlipData.packItems);
    },

    clearPackingSlip() {
      frappe.confirm('Are you sure you want to Clear Packing Slip?',
        () => {
          this.removeUnloadEvent();
          frappe.set_route("Form", 'Packing Slip', this.ps_id);
        }, () => {
        })
    },

    makeInstructionRead() {
      this.packSlipData.instruction_read = !this.packSlipData.instruction_read;
    },

    finishPackingSlip() {
      if (validatePackingSlip(this.packSlipData)) {
        let packListConfirm = new frappe.ui.Dialog({
          fields: [
            {
              label: 'Pack List Confirm Html',
              fieldname: "pack_list_confirm_html",
              fieldtype: 'HTML'
            }
          ],
          primary_action: () => {
            this.removeUnloadEvent();
            this.savePackingSlip();
            packListConfirm.hide();
          },
          primary_action_label: 'Save',
          secondary_action: () => {
            packListConfirm.hide();
          },
          secondary_action_label: 'Continue Editing Packing Slip'
        });
        packListConfirm.show();
        let packingSlipReport = new frappe.essdee_sales.OMS.ui.PackingSlipReport(
          packListConfirm.fields_dict.pack_list_confirm_html.wrapper,
          JSON.stringify(this.makeConfirmBoxData())
        );
        packListConfirm.$wrapper.find('.modal-dialog').css("min-width", "max-content");
      }
    },

    makeConfirmBoxData() {
      return {
        customer: this.pickListData.customer,
        address: this.packSlipData.address,
        sales_partner: this.packSlipData.sales_partner,
        pack_slip: this.ps_id,
        created_date_time: frappe.datetime.nowdate(),
        transport: this.packSlipData.transport,
        array_data: this.packSlipData.packItems,
        comments: this.packSlipData.packSlipComment,
        disable_carton_slip: this.packSlipData.disableCarton,
        is_cancelled: false,
        price_list: this.packSlipData.price_list,
        schemes: this.packSlipData.schemes,
        dispatch_from: this.packSlipData.dispatch_location,
        deliver_to: this.packSlipData.deliver_to,
      };
    },


    savePackingSlip() {
      let data = [];
      for (let i = 0; i < this.packSlipData.packItems.length; i++) {
        for (let j = 0; j < this.packSlipData.packItems[i].length; j++) {
          let item = this.packSlipData.packItems[i][j];
          if (item['order_type'] == 'Secondary') {
            this.packSlipData.order_type = 'Secondary';
          }
          item['row1'] = i;
          item['col1'] = j;
          data.push(item);
        }
      }
      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.packing_slip.packing_slip.edit_packing_slip',
        args: {
          edited_details: JSON.stringify(data),
          packing_slip_id: this.ps_id,
          comments: this.packSlipData.packSlipComment,
          transport: this.packSlipData.transport,
          disable_carton: this.packSlipData.disableCarton ? 1 : 0,
          address: this.packSlipData.address,
          price_list: this.packSlipData.price_list,
          schemes: this.packSlipData.schemes,
          dispatch_location: this.packSlipData.dispatch_location,
          packing_team: this.packSlipData.packing_team,
          deliver_to: this.packSlipData.deliver_to,
          order_type: this.packSlipData.order_type
        },
        freeze: true,
        callback: (response) => {
          if (response.message.status === 200) {
            frappe.set_route('Form', 'Packing Slip', response.message.ps_id);
          }
        }
      });
    }
  },

  mounted() {
    this.getPackingSlipData(this.ps_id);
    this.addUnloadEvent();
  },

  beforeDestroy() {
    this.removeUnloadEvent();
  }
};
</script>

<style scoped>
.flex_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex_col {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.align_bottom {
  align-self: flex-end;
  margin-left: 1rem;
}

.form-control-input {
  min-width: 40px !important;
}


th,
td {
  align-items: flex-start;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btn {
  min-width: 4rem;
  max-width: max-content;
}

.pack-cus-details {
  padding: 5px;
  margin: 15px;
}

.panel-heading {
  font-size: 0.9rem;
  font-weight: 700;
  padding-bottom: 2px;
}

.gap-min {
  gap: 0.1rem;
}

.panel-content {
  background-color: rgb(255, 255, 255);
  padding: 4px;
  border-radius: 5px;
}

.form-min {
  max-width: 4rem;
}

.check {
  max-width: 20px !important;
  max-height: 20px !important;
}

.comment-box {
  margin: 0%;
  width: 35vw;
  padding: 1px;
  min-width: 500px;
}
</style>
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_packing_slip_create_update = _resolveComponent("packing-slip-create-update")

  return ($data.packSlipData.display)
    ? (_openBlock(), _createBlock(_component_packing_slip_create_update, {
        key: 0,
        pickListData: $data.pickListData,
        packSlipData: $data.packSlipData,
        addToPackList: $options.addToPackList,
        removeFromPackList: $options.removeFromPackList,
        addPackListItem: $options.addPackListItem,
        finishPackingSlip: $options.finishPackingSlip,
        clearPackingSlip: $options.clearPackingSlip,
        makeInstructionRead: $options.makeInstructionRead,
        action: 'edit'
      }, null, 8 /* PROPS */, ["pickListData", "packSlipData", "addToPackList", "removeFromPackList", "addPackListItem", "finishPackingSlip", "clearPackingSlip", "makeInstructionRead"]))
    : _createCommentVNode("v-if", true)
}
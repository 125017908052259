<template>
<div class="container">
    <div v-if="credit_details['last_updated']" style="text-align:right">
        Last Updated At : {{get_update_time}}
    </div>
    <table class="table table-bordered">
        <thead>
            <tr>
               <th colspan="2" class="status_header" v-for="head in settingData['headers']" :key="head">{{head}}</th> 
            </tr>
        </thead>
        <tbody>
            <tr>
                <td colspan="2" v-for="data,index in settingData['headers']" :key="index">
                    <div class="status-value" :id="settingData['id'][index]+'value'"></div>
                    <div class="progress-bar-container">
                        <div class="progress-bar" :id="settingData['id'][index]"></div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="5">Total Outstanding: {{numberToLakhs(credit_details['total_outstanding'])}}</td>
                <td colspan="5">Credit Limit: {{numberToLakhs(credit_details['credit_limit'])}}</td>
            </tr>
            <tr>
                <td colspan="10">
                    <table class='table borderless table-striped'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Current 12 Months</th>
                                <th>Previous 12 Months</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Turnover</td>
                                <td>{{numberToLakhs(credit_details['current_turnover'])}}</td>
                                <td>{{numberToLakhs(credit_details['previous_turnover'])}}</td>
                            </tr>
                            <tr>
                                <td>Total Paid Amount</td>
                                <td>{{numberToLakhs(credit_details['paid_total_amt'])}}</td>
                                <td>{{numberToLakhs(credit_details['previous_total_paid_amt'])}}</td>
                            </tr>
                            <tr>
                                <td>Avg Payment Days</td>
                                <td>{{(credit_details['avg_paid_days'])+" Days"}}</td>
                                <td>{{(credit_details['previous_avg_paid_days'])+" Days"}}</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>
    
</template>

<script>
import moment from 'moment';

export default {
  name: 'CustomerCreditDisplay',
  props: {
    credit_details: {
      type: Object,
      required: true
    }
  },
  data() {

    return {
      settingData: {
        data: [],
        headers: [],
        colors: [],
        id: []
      }
    };
    
  },
  computed: {

    get_update_time() {
      return new Date(this.credit_details['last_updated']).toLocaleDateString()
    }

  },

  methods: {

    numberToLakhs(num) {
      if (isNaN(num)) return '0L';
      const lakhs = num / 100000;
      return lakhs.toFixed(2) + 'L';
    },

    setProgressBar(value, id) {
      const progressBar = document.getElementById(id);
      if (progressBar) {
        progressBar.style.backgroundColor = value;
      }
    },

    setProgress(value, id) {
      const progressBar = document.getElementById(id);
      if (progressBar) {
        progressBar.style.width = `${value}%`;
      }
    },

    getCreditSettings() {
      frappe.call({
        method: "essdee_sales.sales_order_management.doctype.customer_credit_settings.customer_credit_settings.get_credit_settings",
        callback: (response) => {
          response.message.sort((a, b) => a['range_type'].localeCompare(b['range_type']));
          this.settingData.data = response.message;
          this.generateTableData();
        }
      });
    },

    generateTableData() {
      this.settingData.headers = this.settingData.data.map(item => item['range']);
      this.settingData.colors = this.settingData.data.map(item => item['color']);
      this.settingData.id = this.settingData.data.map(item => item['range_type']);

      this.$nextTick(() => {
        this.settingData.id.forEach((id, index) => {
          this.setProgressBar(this.settingData.colors[index], id);
          this.setDisplayValue(id, this.credit_details[id]);
        });
      });
    },

    setRangeValues() {
      this.getCreditSettings();
    },

    setDisplayValue(id, value) {
      const total = Number(this.credit_details['total_outstanding']);
      value = Number(value);
      const percent = value === 0 ? 0 : ((value / total) * 100).toFixed(0);
      this.setProgress(percent, id);
      document.getElementById(id + 'value').innerHTML = `${percent}% (${this.numberToLakhs(value)})`;
    }

  },


  mounted() {
    this.setRangeValues();
  }
};
</script>

<style scoped>


table {
    text-align: center;
    min-width: 100%;
}


.progress-bar-container {
    width: 100%;
    background-color: #f1f1f1;
    border-radius: 25px;
    overflow: hidden;
}

.progress-bar {
    width: 0;
    height: 0.8rem;
    text-align: center;
    line-height: 30px;
    color: white;
    border-radius: 25px 0 0 25px;
    transition: width 0.5s linear;
}


.borderless td, .borderless th {
    border: none;
}


</style>
<template>
  <div class="multi-select" ref="multiSelect">
    <div class="dropdown" @click="toggleDropdown">
      <div class="selected-options">
        <span v-if="!selectedValues.length" class="placeholder">{{placeholder}}</span>
        <span v-for="(value, index) in selectedValues" :key="index" class="tag">
          {{ value['option'] }}
          <button @click.stop="removeOption(value)" class="remove-btn">x</button>
        </span>
      </div>
    </div>

    <div v-if="isOpen" class="options-list">
        <template v-for="(option) in options" v-if="options.length!=0">
             <div
                :key="option"
                v-if="!selectedValues.includes(option)"
                @click.stop="selectOption(option)"
                :class="{ selected: selectedValues.includes(option) }"
                >
              {{ option['option'] }}
            </div>
        </template>
        <div v-else>No Options Available</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MultiSelectFilter',
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder : {
        type : String,
        required: false
    },
    triggerEvent :{
      type : Function,
      required : true
    },
    setDefault : {
      type : Boolean,
      required : true
    },
    defaultList : {
      type : Array,
      required : true
    }
  },
  data() {
    return {
      selectedValues: [],
      isOpen: false,
    };
  },
  mounted() {
    this.addClickEvent();
    if(this.setDefault && this.defaultList.length != 0){
      this.setDefaultValues();
    }
  },
  unmounted() {
    this.removeClickEvent();
  },

  methods: {

    addClickEvent(){
      document.addEventListener('click', this.handleClickOutside);
    },

    removeClickEvent(){
      document.removeEventListener('click', this.handleClickOutside);
    },

    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },

    setDefaultValues() {
      this.options.forEach(element => {
        if(this.defaultList.includes(element['id'])){
          this.selectedValues.push(element);
        }
      });
    },

    selectOption(option) {
      if (this.selectedValues.includes(option)) {
        this.selectedValues = this.selectedValues.filter((val) => val !== option);
      } else {
        this.selectedValues.push(option);
      }
      this.handleChange();
    },

    removeOption(option) {
      this.selectedValues = this.selectedValues.filter((val) => val !== option);
      this.handleChange();
    },

    handleClickOutside(event) {
      if (!this.$refs.multiSelect.contains(event.target)) {
        this.isOpen = false;
      }
    },

    handleChange(){
      this.triggerEvent(this.selectedValues);
    }
  },
};
</script>

<style scoped>
.multi-select {
  width: 100%;
  max-width: 100%;
  align-self: center;
  position: relative;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.dropdown {

  padding: 10px;
  border-radius: 8px;
  background-color: var(--control-bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.placeholder {
  color:var(--text-color);
}

.selected-options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow: hidden;
}

.tag {
  background-color: var(--control-bg);
  border: 1px solid var(--text-color);
  border-radius: 25px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  color: var(--text-color);
}

.remove-btn {
  background: none;
  border: none;
  margin-left: 6px;
  font-weight: bold;
  color: var(--text-color);
  cursor: pointer;
  border-radius: 50%;
  padding: 0;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-btn:hover {
  background-color: var(--control-bg);
}

.options-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: var(--control-bg);
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 8px;
  z-index: 10;
  transition: max-height 0.3s ease;
}

.options-list div {
  padding: 5px 8px;
  cursor: pointer;
  color: var(--text-color);
}

.options-list div:hover {
  background-color: var(--text-color);
  color: var(--control-bg);
}

</style>

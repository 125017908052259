<template>
    <div ref='root' class="container ">
        <div><h3>Datewise Item Packed</h3></div>
        <div class="filter-section flex_row">
            <div class="start-date select-section"></div>
            <div class="end-date select-section"></div>
            <div class="sales-partner select-section"></div>
            <div class="customer select-section"></div>
            <div>
                <button class="btn btn-success" @click="fetchItemDetails">Show</button>
            </div>
        </div>
        <div v-if="reportData.display && reportData.data.length>0" class="table-responsive">
            <table class="table table-bordered">
                <tr>
                    <td colspan="100%" class="filter">
                        <input type="text" @input="changeQuery" class="form-control" id="search-filter" placeholder="Start typing to filter">
                    </td>
                </tr>
				<tr v-for="item_row in reportData.displayData" :key="item_row" class="table-responsive">
					<table class="table table-bordered">
						<thead>
							<tr class="grid-heading-row">
								<th class="sno">S.No</th>
								<th>Item Name</th>
								<th v-for="sec in item_row[0]['attributes']" :key="sec">{{sec}}</th>
								<template v-if="item_row[0]['primary_attribute']">
									<th v-for="pri in item_row[0]['primary_attribute_values']" :key="pri">{{pri}}</th>
								</template>
								<th class="fixed">Total Boxes</th>
							</tr>
						</thead>
						<tbody class="hover-pointer">
							<tr v-for="item,index in item_row" :key="item" @click.prevent="makeItemPopup(item['item_name'])">
								<td>{{index+1}}</td>
								<td>{{item['item_name']}}</td>
								<td v-for="sec in item_row[0]['attributes']" :key="sec">{{item['attribute_values'][sec]}}</td>
								<template v-if="item['primary_attribute']">
									<td v-for="pri in item_row[0]['primary_attribute_values']" :key="pri">{{item['qty'][pri] && item['qty'][pri] > 0 ? item['qty'][pri] : "--"}}</td>
								</template>
								<td>{{item['total_boxes']}}</td>
							</tr>
						</tbody>
					</table>
				</tr>
            </table>
        </div>
        <div v-else>
            <nothing-to-display/>
        </div>
    </div>
</template>

<script>

import NothingToDisplay from '../Components/NothingToDisplay.vue';
import { isMatchInData } from '../Utils/filters';
import { getTotalBoxes, combineTableData } from '../Utils';

export default {
    name: 'DatewiseItemPacked',
    components: {
        NothingToDisplay
    },
    data() {
        return {
            sample_doc: {},
            sales_partner: { value: null },
            customer: { value: null },
            start: { value: null },
            end: { value: null },
            reportData: {
                display: false,
                displayData: [],
                data: []
            },
            searchQuery: ""
        };
    },
    methods: {
        changeQuery(event) {
            this.searchQuery = event.target.value;
            this.makeQueryDisplayData();
        },

        addToTable(obj) {
            combineTableData(obj, this.reportData.data);
        },

        makeQueryDisplayData() {
            this.reportData.displayData = [];
            for (let i = 0; i < this.reportData.data.length; i++) {
                let tempArr = [];
                for (let j = 0; j < this.reportData.data[i].length; j++) {
                    if (this.searchQuery === '') {
                        tempArr.push(this.reportData.data[i][j]);
                    } else {
                        if (isMatchInData(this.searchQuery, this.reportData.data[i][j])) {
                            tempArr.push(this.reportData.data[i][j]);
                        }
                    }
                }
                if (tempArr.length > 0) {
                    this.reportData.displayData.push(tempArr);
                }
            }
        },

        makeFormattedData(data) {
            for (let j = 0; j < data.length; j++) {
                let obj = data[j];
                obj['total_boxes'] = getTotalBoxes(obj);
                this.addToTable(obj);
            }
            this.makeQueryDisplayData();
        },

        clearExistData() {
            this.reportData.display = false;
            this.reportData.data = [];
            this.searchQuery = "";
        },

        makeFilterComponents() {
            const el = this.$refs['root'];
            $(el).find(".start-date").html("");
            $(el).find(".end-date").html("");
            $(el).find(".sales-partner").html("");
            $(el).find(".customer").html("");

            this.start = frappe.ui.form.make_control({
                parent: $(el).find('.start-date'),
                df: {
                    fieldtype: "Datetime",
                    fieldname: "start_date",
                    label: "Start Date and Time",
                    reqd: true
                },
                doc: this.sample_doc,
                render_input: true,
            });

            this.end = frappe.ui.form.make_control({
                parent: $(el).find('.end-date'),
                df: {
                    fieldtype: "Datetime",
                    fieldname: "end_date",
                    label: "End Date and Time",
                    reqd: true
                },
                doc: this.sample_doc,
                render_input: true,
            });

            this.sales_partner = frappe.ui.form.make_control({
                parent: $(el).find('.sales-partner'),
                df: {
                    fieldtype: "Link",
                    fieldname: "sales_partner",
                    label: "Sales Partner",
                    options: 'Sales Partner'
                },
                doc: this.sample_doc,
                render_input: true,
            });

            this.customer = frappe.ui.form.make_control({
                parent: $(el).find('.customer'),
                df: {
                    fieldtype: "Link",
                    fieldname: "customer",
                    label: "Customer",
                    options: 'Customer'
                },
                doc: this.sample_doc,
                render_input: true,
            });
        },

        fetchItemDetails() {
            if (!this.start.get_value() || !this.end.get_value()) {
                frappe.msgprint("Please Select The Start Date Time and End Date Time");
                return;
            }
            if (new Date(this.end.get_value()) > (new Date())){
                frappe.show_alert({
                    "message" : "Travelling To Future Is Not Enabled",
                    "indicator" : "red"
                });
                return;
            }
            this.clearExistData();
            frappe.call({
                method: "essdee_sales.sales_order_management.doctype.packing_slip.packing_slip.make_datewise_pack_slip_report",
                args: {
                    'start_date': this.start.get_value(),
                    'end_date': this.end.get_value(),
                    'sales_partner': this.sales_partner.get_value(),
                    'customer': this.customer.get_value()
                },
                freeze : true,
                freeze_message : "Fetching Details",
                callback: (response) => {
                    response = response.message;
                    if (response.status === 200) {
                        this.makeFormattedData(response.data);
                        this.reportData.display = true;
                    }
                }
            });
        },

        makeItemPopup(item_name) {
            let dialog = new frappe.ui.Dialog({
                title: item_name + ' Packing Slip Details',
                fields: [
                    {
                        label: 'item_display_html',
                        fieldname: 'item_display_html',
                        fieldtype: 'HTML'
                    }
                ]
            });
            const from_date = this.start.get_value();
            const to_date = this.end.get_value();
            const sales_part = this.sales_partner.get_value();
            const cust = this.customer.get_value();
            let customerItemReport = new frappe.essdee_sales.OMS.ui.CustomerwisePackingSlipReport(
                dialog.fields_dict.item_display_html.wrapper,
                JSON.stringify({
                    item_name: item_name,
                    from_date: from_date,
                    to_date: to_date,
                    sales_part: sales_part,
                    cust: cust
                })
            );
            dialog.$wrapper.find('.modal-dialog').css({
                'min-width': '90vw',
                'transition-duration': '250ms',
                'transition-timing-function': 'linear'
            });
            dialog.show();
        }
    },
    mounted() {
        this.makeFilterComponents();
    }
};
</script>


<style scoped>

.container{
    margin-top: 1rem;
}

.flex_row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.select-section{
    width: 20rem;
}

table {
  width: 100%;
}
.hover-pointer :hover{
	cursor: pointer;
}

.filter{
	border: none;
}
</style> 
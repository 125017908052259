<template>
    <div class="container" ref="root">
        <h3>Itemwise Pending Report</h3>
        <multi-select-filter 
            v-if="warehouseData.display" 
            :options="warehouseData.options"
            :placeholder="'Select A Warehouse'" 
            :triggerEvent="handleWarehouseChange"
            :setDefault="warehouseData.defaultData.length >0 ? true: false"
            :defaultList="warehouseData.defaultData"
        />
        <div v-if="reportData.display" class="table-responsive">
            <table class="table table-bordered">
				<tr>
                    <td colspan="100%" class="filter">
    		            <input type="text" @input="changeQuery" class="form-control" id="search-filter" placeholder="Start typing to filter">
                    </td>
                </tr>
                <tr v-if="showSizeTypeTable && sizeTypeItemDetailsDisplay.length!=0">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="grid-heading-row">
                                <th class="sno">S.NO</th>
                                <th>Item Name</th>
                                <th class="fixed"></th>
                                <th v-for="size in sizeTypeHeaders" style="text-align: center;">
                                    <template v-for="s in sizeTypeDisplayHeaders[size]">
                                        {{s}}<br>
                                    </template>
                                </th>
                                <th>Total Boxes</th>
                            </tr>                            
                        </thead>
                        <tbody class="hover-pointer">
                            <template v-for="i,index in sizeTypeItemDetailsDisplay">
                                <tr @click.prevent="makeItemPopup(i['item_name'])">
                                    <td rowspan="2">{{ index+1 }}</td>
                                    <td rowspan="2">{{ i['item_name'] }}</td>
                                    <td>Order</td>
                                    <td v-for="size in sizeTypeHeaders">
                                        {{ i['qty'][sizeTypeData[i['size_type']][size]]? i['qty'][sizeTypeData[i['size_type']][size]] : '--' }}
                                    </td>
                                    <td>{{ i['total_boxes']? i['total_boxes'] : "--" }}</td>
                                </tr>
                                <tr :class=" (reportData.show_stock == true)? '' : ' hidden-row '">
                                    <td>Stock</td>
                                    <td v-for="size in sizeTypeHeaders" :class=" 'table-ind '+(i['qty']['stock'][sizeTypeData[i['size_type']][size]]? i['qty']['stock'][sizeTypeData[i['size_type']][size]]['indicator'] : '')">
                                        {{ i['qty']['stock'][sizeTypeData[i['size_type']][size]]? i['qty']['stock'][sizeTypeData[i['size_type']][size]]['val'] : '--' }}
                                    </td>
                                    <td>{{ i['total_stock_boxes']? i['total_stock_boxes'] : "--" }}</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </tr>
				<tr v-for="item_row in reportData.displayData" :key="item_row" class="table-responsive">
					<table class="table table-bordered">
						<thead>
							<tr class="grid-heading-row">
								<th class="sno" >S.No</th>
								<th>Item Name</th>
								<th v-for="sec in item_row[0]['attributes']" :key="sec">{{sec}}</th>
                                <th class="fixed"></th>
								<template v-if="item_row[0]['primary_attribute']">
									<th v-for="pri in item_row[0]['primary_attribute_values']" :key="pri">{{pri}}</th>
								</template>
								<th v-else>Quantity</th>
								<th class="fixed">Total Boxes</th>
							</tr>
						</thead>
						<tbody class="hover-pointer">
                            <template v-for="item,index in item_row" :key="index">
						    	<tr @click.prevent="makeItemPopup(item['item_name'])">
						    		<td rowspan="2">{{index+1}}</td>
						    		<td rowspan="2">{{item['item_name']}}</td>
						    		<td v-for="sec in item_row[0]['attributes']" :key="sec" rowspan="2">{{item['attribute_values'][sec]}}</td>
                                    <td>Order</td>
						    		<template v-if="item['primary_attribute']">
						    			<td v-for="pri in item_row[0]['primary_attribute_values']" :key="pri" >
                                            {{item['qty'][pri] !=null ? item['qty'][pri] : "--"}}
                                        </td>
						    		</template>
                                    <template v-else>
                                        <td>{{item['qty']['default'] !=null ? item['qty']['default'] : "--"}}</td>
                                    </template>
						    		<td>{{item['total_boxes'] !=null ? item['total_boxes'] : "--"}}</td>
						    	</tr>
                                <tr :class=" (reportData.show_stock == true)? '' : ' hidden-row '">
                                    <td>Stock</td>
                                    <template v-if="item['primary_attribute']">
						    			<td v-for="pri in item_row[0]['primary_attribute_values']" :key="pri" :class=" 'table-ind '+item['qty']['stock'][pri]['indicator']">
                                            {{item['qty']['stock'][pri]['val'] !=null ? item['qty']['stock'][pri]['val'] : "--"}}
                                        </td>
						    		</template>
                                    <template v-else>
                                        <td :class="'table-ind'">
                                            {{item['qty']['stock']['default']['val'] !=null ? item['qty']['stock']['default']['val'] : "--"}}
                                        </td>
                                    </template>
						    		<td>
                                        {{item['total_stock_boxes'] !=null ? item['total_stock_boxes'] : "--"}}
                                    </td>

                                </tr>
                            </template>
						</tbody>
					</table>
				</tr>
            </table>
        </div>
		<div v-if="reportData['displayData'].length==0 && sizeTypeItemDetailsDisplay.length == 0">
			<nothing-to-display/>
		</div>
    </div>
</template>

<script>
import { isMatchInData } from '../Utils/filters';
import NothingToDisplay from '../Components/NothingToDisplay.vue';
import { combineTableData } from '../Utils';
import MultiSelectFilter from '../Components/MultiSelectFilter.vue';

export default {

    name: 'ItemwisePendingReport',

    components: {
        NothingToDisplay,
        MultiSelectFilter
    },

    data() {
        return {
            reportData: {
                display: false,
                displayData: [],
                originalData: [],
                showSizeTypeTable : false,
            },
            searchQuery: "",
            warehouseData : {
                options : [],
                defaultData : [],
                display : false,
            },
            sizeTypeData : {},
            sizeTypeHeaders : ["size1","size2","size3","size4","size5","size6","size7","size8","size9","size10"],
            sizeTypeDisplayHeaders : {},
            sizeTypeItemDetailsDisplay : []
        };
    },

    methods: {

        changeQuery(event) {
            this.searchQuery = event.target.value;
            this.makeQueryDisplayData();
        },

        addToTable(obj) {
            combineTableData(obj, this.reportData.originalData);
        },

        makeQueryDisplayData() {
            this.reportData.displayData = [];
            this.sizeTypeItemDetailsDisplay = [];
            for (let i = 0; i < this.reportData.originalData.length; i++) {
                let tempArr = [];
                for (let j = 0; j < this.reportData.originalData[i].length; j++) {
                    if (this.searchQuery === '') {
                        tempArr.push(this.reportData.originalData[i][j]);
                    } else {
                        if (isMatchInData(this.searchQuery, this.reportData.originalData[i][j])) {
                            tempArr.push(this.reportData.originalData[i][j]);
                        }
                    }
                }
                if (tempArr.length > 0) {
                    if(tempArr[0]['has_size_type'] == true){
                        for(let k=0;k<tempArr.length;k++){
                            this.sizeTypeItemDetailsDisplay.push(tempArr[k]);
                        }
                        continue;
                    }
                    this.reportData.displayData.push(tempArr);
                }
            }
        },

        getTotalBoxes(obj) {
            if (!obj['primary_attribute']) {
                return [Number(obj['qty']['default']),Number(obj['qty']['stock']['default']['val'])];
            }
            let total1 = 0;
            let total2 = 0;
            for(let i=0;i<obj['primary_attribute_values'].length;i++) {
                let key = obj['primary_attribute_values'][i];
                total1 += Number(obj['qty'][key]);
                total2 += Number(obj['qty']['stock'][key]['val']);
            }
            return [total1,total2];
        },

        makeFormattedData(data) {
        
            for(let i=0; i<data.length; i++){
                let temp = this.getTotalBoxes(data[i]);
                data[i]['total_boxes'] = temp[0];
                data[i]['total_stock_boxes'] = temp[1];
                this.addToTable(data[i]);
            }
            this.makeQueryDisplayData();
        },

        clearExistData() {
            this.reportData.display = false;
            this.reportData.originalData = [];
        },

        fetchAllPendingData(item_name,warehouseList) {
            this.clearExistData();
            frappe.call({
                method: "essdee_sales.sales_order_management.doctype.order.order.get_all_pending_items",
                args: {
                    item_name: item_name,
                    warehouse_list : warehouseList
                },
                freeze : true,
                freeze_message: __("Fetching Details..."),
                callback: (response) => {
                    response = response.message;
                    if (response.status === 200) {
                        this.makeFormattedData(response.data);
                        this.showSizeTypeTable = response.show_size_type;
                        this.sizeTypeData = response.size_types;
                        if(response.size_types){
                            this.computeSizeTypeHeaders();
                        }
                        this.reportData.show_stock = response.show_stock;
                        this.reportData.display = true;
                    }
                }
            });
        },

        computeSizeTypeHeaders(){
            let sizeTypeHeaders = {};
            let keys = Object.keys(this.sizeTypeData);
            for(let i=0;i<keys.length;i++){
                if(this.sizeTypeData[keys[i]]['show'] == 0){
                    continue;
                }
                for(let j=0;j<this.sizeTypeHeaders.length;j++){
                    let sizeH = this.sizeTypeHeaders[j];
                    if(!sizeTypeHeaders[sizeH]){
                        sizeTypeHeaders[sizeH] = [];
                    }
                    sizeTypeHeaders[sizeH].push(this.sizeTypeData[keys[i]][sizeH]);
                }
            }
            this.sizeTypeDisplayHeaders = sizeTypeHeaders;
        },

        makeItemPopup(item_name) {
            let dialog = new frappe.ui.Dialog({
                title: item_name + ' Order Details',
                fields: [
                    {
                        label: 'item_display_html',
                        fieldname: 'item_display_html',
                        fieldtype: 'HTML'
                    }
                ],
                primary_action: () => {
                    customerItemReport.makePickList();
                    dialog.hide();
                },
                secondary_action : ()=>{
                    customerItemReport.makeBulkItemCancel();
                },
                secondary_action_label : "Close Pending Orders For Selected Items",
                primary_action_label: 'Make PickList'
            });
            let customerItemReport = new frappe.essdee_sales.OMS.ui.CustomerwiseItemPendingReport(
                dialog.fields_dict.item_display_html.wrapper,
                item_name
            );
            dialog.$wrapper.find('.modal-dialog').css({
                'min-width': '90vw',
            });
            dialog.show();
        },

        getWarehouses(){
            frappe.call({
                method : "essdee_sales.sales_order_management.doctype.warehouse.warehouse.get_warehouse_list",
                callback : (response) => {
                    for(let i=0;i<response['message'].length;i++){
                        this.warehouseData.options.push({
                            "option" : response['message'][i]['warehouse_name'],
                            "id" : response['message'][i]['warehouse']
                        });
                        if(response['message'][i]['is_default'] == 1){
                            this.warehouseData.defaultData.push(response['message'][i]['warehouse']);
                        }
                    }
                    this.warehouseData.display = true;
                }
            })
        },

        handleWarehouseChange(selectedValues) {
            this.fetchAllPendingData(null,selectedValues.map((ele)=>ele['id']));
        },

        handleMountedEvent(){
            this.getWarehouses();
            this.fetchAllPendingData();
        }
    },
	
    mounted() {
        this.handleMountedEvent();
    }
};
</script>


<style scoped>
.container{
    margin-top: 1rem;
}

table {
  width: 100%;
}

.hover-pointer :hover{
	cursor: pointer;
}

.filter{
	border: none;
}

.warn {
    border-bottom: 5px solid;
    border-bottom-color: orange;
}

.not_avail{
    border-bottom: 5px solid;
    border-bottom-color: red;
}

.avail {
    border-bottom: 5px solid;
    border-bottom-color: green;
}

.hidden-row {
  height: 0px; 
  font-size: 0%;
}

.hidden-row td {
  padding: 0;
  margin: 0;
  height: 0px;
  line-height: 0px;
  overflow: hidden;
}
</style>
<template>
<div class="container">
    <div v-if="reportData.display" class="table-responsive">
    	<input type="text" @input="changeQuery" class="form-control query-input" id="search-filter" placeholder="Start typing to filter">
        <table class="table table-bordered" v-if="reportData['displayData'].length!=0">
            <thead class="grid-heading-row">
                <tr>
                    <th>Order Date</th>
                    <th>Order</th>
                    <th>Customer</th>
                    <th>City</th>
                    <th v-for="key in reportData['displayData'][0]['attributes']" :key="key">{{key}}</th>
                    <template v-if="reportData['displayData'][0]['primary_attribute']">
                        <th v-for="key in reportData['displayData'][0]['primary_attribute_values']" :key="key">{{key}}</th>
                    </template>
                    <th v-else>Quantity</th>
                    <th>Boxes</th>
                    <th class="fixed">Multiplier</th>
                    <th>Total Boxes</th>
                    <th class="fixed">Picked</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item1) in reportData['displayData']" :key="item1">
                    <td>{{item1.order_date}}</td>
                    <td>{{item1.order}}</td>
                    <td>{{item1.customer}}</td>
                    <td>{{item1.city? item1.city : "--"}}</td>
                    <td v-for="key in reportData['displayData'][0]['attributes']" :key="key">{{item1['attribute_values'][key]}}</td>
                    <template v-if="item1['primary_attribute']">
                        <td v-for="key in reportData['displayData'][0]['primary_attribute_values']" :key="key">{{(item1['qty'][key] && item1['qty'][key] > 0)? item1['qty'][key] : '--'}}</td>
                    </template>
                    <td v-else>{{item1['qty']['default']}}</td>
                    <td>{{item1.tot}}</td>
                    <td>{{item1.multiplier}}</td>
                    <td>{{item1.tot_quan}}</td>
                    <td>
                        <div class="ml-5 cursor">
                            <input type="checkbox" v-model="item1.isPickListed">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <nothing-to-display v-else />
    </div>
    <nothing-to-display v-else />

</div>
</template>

<script>
import NothingToDisplay from './NothingToDisplay.vue';
import { isMatchInData } from '../Utils/filters';

export default {
  name: 'CustomerwiseItemPendingReport',
  
  props: {
    item_name: {
      type: String,
      required: true
    }
  },

  components: {
    NothingToDisplay
  },

  data() {
    return {
      reportData: {
        data: [],
        displayData: [],
        display: false
      },
      searchQuery: ''
    };
  },

  methods: {

    changeQuery(event) {
      this.searchQuery = event.target.value;
      this.makeQueryDisplayData();
    },

    makeQueryDisplayData() {
      this.reportData.displayData = [];
      for (let i = 0; i < this.reportData.data.length; i++) {
        if (this.searchQuery === '') {
          this.reportData.displayData.push(this.reportData.data[i]);
        } else {
          if (isMatchInData(this.searchQuery, this.reportData.data[i])) {
            this.reportData.displayData.push(this.reportData.data[i]);
          }
        }
      }
    },

    addItem(newItem) {
      this.reportData.data.push(newItem);
    },

    makeTotalQuantities(orderItem) {
      if (orderItem.primary_attribute) {
        let tot = 0;
        for (let i = 0; i < orderItem.primary_attribute_values.length; i++) {
          let key = orderItem.primary_attribute_values[i];
          tot += Number(orderItem.qty[key]);
        }
        orderItem.tot = tot;
        orderItem.tot_quan = tot * Number(orderItem.multiplier);
      } else {
        orderItem.tot = orderItem.qty.default;
        orderItem.tot_quan = orderItem.tot * Number(orderItem.multiplier);
      }
    },

    makeDisplayableData(itemData) {
      for (let i = 0; i < itemData.length; i++) {
        itemData[i].order_date = frappe.format(itemData[i].order_date, { fieldtype: 'Date' });
        this.makeTotalQuantities(itemData[i]);
        this.addItem(itemData[i]);
      }
      this.makeQueryDisplayData();
    },

    makePickList() {
      let data = [];
      for (let i = 0; i < this.reportData.data.length; i++) {
        data.push(this.reportData.data[i]);
      }

      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.order.order.make_pick_list_item_wise',
        args: {
          pick_listed_data: JSON.stringify(data)
        },
        callback: (response) => {
          response = response.message;
          if (response.status === 200) {
            frappe.msgprint("Pick List Updated");
          }
        }
      });
    },

    makeBulkItemCancel(){
      let data = [];
      for(let i=0;i<this.reportData.data.length; i++){
        if(this.reportData.data[i].isPickListed){
          data.push(this.reportData.data[i]);
        }
      }

      if(data.length == 0){
        frappe.msgprint("Please Select A Item");
        return;
      }

      let dialog = new frappe.ui.Dialog({
          title: 'Order Item Cancelling',
          fields: [
          	  {
          	    "fieldtype" : "Date",
          	    "fieldname" : "order_items_closing_date",
          	    "label" : "Order Items Closing Date",
          	    "default" : "Today",
          	    "read_only" : 1
          	  },
			  {
			   "fieldname": "column_break_vwbs",
			   "fieldtype": "Column Break"
			  },
			  {
  			   "fieldname": "item_details_section",
  			   "fieldtype": "Section Break",
  			  },
          	  {
          	    "fieldname" : "item_display_html",
          	    "fieldtype" : "HTML"
          	  },
			  {
  			   "fieldname": "item_details_section1",
  			   "fieldtype": "Section Break",
  			  },

          	  {
          	    "fieldname": "reason",
          	    "fieldtype": "Select",
          	    "label": "Reason For Closing Order",
          	    "options": "\nCancelled  by agent\nCancelled by party\nOrder Repetition\nSize ratio adjustment/ Carton adjustment\nOther",
          	    "reqd": 1
          	  },
			  {
			   "fieldname": "column_break_vwbs1",
			   "fieldtype": "Column Break"
			  },
          	  {
          	  "fieldname": "comment",
          	  "fieldtype": "Small Text",
          	  "label": "Comment",
          	  "mandatory_depends_on": "eval:doc.reason=='Other'",
          	 },
          ],
          primary_action: (values) => {
            frappe.call({
        	  method : "essdee_sales.sales_order_management.doctype.multi_order_cancel_request.multi_order_cancel_request.make_multi_order_cancel_request",
        	  args : {
        	    cancel_date : values.order_items_closing_date,
        	    data : JSON.stringify(data),
        	    reason : values.reason,
        	    comment : values.comment? values.comment : "",
        	   },
        	   callback : (response) =>{
        	      if(response.message.status == 200){
        	        frappe.show_alert({
        	          "message" : response.message.message,
        	          "indicator" : "Green"
        	        });
        	        if(response.message.is_owner == true ){
        	          setTimeout(()=>{window.location.reload()},2000);
        	        }
        	      }
        	      else{
        	        frappe.msgprint(response.message.message);
        	      }
        	   }
        	})
            dialog.hide();
          },
          secondary_action : ()=>{
            dialog.hide();
          },
          secondary_action_label : "Close",
          primary_action_label: 'Confirm'
      });
      let mutliOrderCancelDisplay = new frappe.essdee_sales.OMS.ui.MultiOrderCancelItemDisplay(
          dialog.fields_dict.item_display_html.wrapper,
          data
      );
      dialog.$wrapper.find('.modal-dialog').css({
          'min-width': '90vw',
          'transition-duration': '250ms',
          'transition-timing-function': 'linear'
      });
      dialog.show();
    },

    fetchItemCustomerdata(item_name) {
      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.order.order.customer_wise_item_pending',
        args: {
          item_name: item_name
        },
        callback: (response) => {
          response = response.message;
          if (response.status === 200) {
            this.makeDisplayableData(response.data);
            this.reportData.display = true;
          }
        }
      });
    }

  },

  mounted() {
    this.fetchItemCustomerdata(this.item_name);
  }
};
</script>

<style scoped>
table {
  width: 100%;
}
.query-input{
    margin: 1.3rem;
    max-width: 95%;
}
</style>
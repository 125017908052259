function checkValueMatch(query,string) {
    if (string==null) return false;
    string = string.toString();
    return string.toLowerCase().includes(query.toLowerCase());
}

function findMatchInAttributes(query,attrArr) {
    if (Array.isArray(attrArr)) {
        for(let i=0;i<attrArr.length;i++) {
            let attrObj = attrArr[i];
            let key = Object.keys(attrObj)[0];
            if (checkValueMatch(query,attrObj[key])) return true;
        }
    } else {
        let keys = Object.keys(attrArr);
        for(let i=0;i<keys.length;i++) {
            if (checkValueMatch(query,attrArr[keys[i]])) return true;
        }
    }
  
    return false;
}

function isMatchInData(query,data) {
    if (data.hasOwnProperty('item_name') && checkValueMatch(query,data['item_name'])) return true;
    if (data.hasOwnProperty('order_date') && checkValueMatch(query,data['order_date'])) return true;
    if (data.hasOwnProperty('order') && checkValueMatch(query,data['order'])) return true;
    if (data.hasOwnProperty('customer') && checkValueMatch(query,data['customer'])) return true;
    if (data.hasOwnProperty('city') && checkValueMatch(query,data['city'])) return true;
    if (data.hasOwnProperty('tot') && checkValueMatch(query,data['tot'])) return true;
    if (data.hasOwnProperty('tot_quan') && checkValueMatch(query,data['tot_quan'])) return true;
    if (data.hasOwnProperty('multiplier') && checkValueMatch(query,data['multiplier'])) return true;
    let local_match = findMatchInAttributes(query,data['attribute_values']);
    if (local_match) return true;
    if (data['primary_attribute']) {
        local_match = findMatchInAttributes(query,data['qty']);
        if (local_match) return true;
    } else {
        if (data.hasOwnProperty('quantity') && checkValueMatch(query,data['quantity'])) {
            return true;
        }
    }
    if (data.hasOwnProperty('total_boxes') && checkValueMatch(query,data['total_boxes'])) return true;
    return false;
}

module.exports =  {
    checkValueMatch,
    findMatchInAttributes,
    isMatchInData
}
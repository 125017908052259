<template>
    <div class="main flex_col">
        <div>
            <label for="cut_status">Customer Credit Status</label>
            <input type="text" class="form-control" id="cut_status" disabled v-model="creditDetails.status">
        </div>
        <div class="flex_row" v-if="creditDetails.show_status">
            <button class="btn btn-secondary flex_row" @click.prevent="changeState('Not Set')">
                Not Set
            </button>
            <button class="btn btn-secondary flex_row" @click.prevent="calculateField()">
                <div v-html="frappe.utils.icon('refresh','md')"></div>
                Calculate Status
            </button>
            <button class="btn btn-secondary flex_row" @click.prevent="changeState('Stop')">
                <div v-html="frappe.utils.icon('sd-stop','md')"></div>
                Stop
            </button>
        </div>
        <div v-if="creditDetails.show_once_check">
           <div class="flex_row" v-if="creditDetails.show_order_entry" >
                <input class="form-control" type="checkbox" id="order_entry_once" v-model="creditDetails.allow_order" @change.prevent="toggleAllowOnce"/>
                <label for="order_entry_once">Allow Order Entry (Once)</label>
           </div>
            <div class="flex_row" v-if="creditDetails.show_packing_entry" >
                <input class="form-control" type="checkbox" id="packing_entry_once" v-model="creditDetails.allow_packing" @change.prevent="toggleAllowOnce"/>
                <label for="packing_entry_once">Allow Packing Slip Entry (Once)</label>
            </div>
        </div>
        <div v-if="creditDetails.display" class="flex_col">
            <div>
                <label for="cur_sts_comment">Comments/Reason for change</label>
                <input type="text" class="form-control" id="cur_sts_comment" style="min-width : 80%" v-model="creditDetails.comment">
            </div>
            <div class="flex_row">
                <button class="btn btn-primary" @click.prevent="saveUpdate()">Update</button>
                <button class="btn btn-light" @click.prevent="cancelUpdate()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CustomerStatusChanger',

  props: {
    credit_status: {
      type: String,
      required: true
    },
    allow_order: {
      type: Number,
      required: true
    },
    allow_packing: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      creditDetails: {
        status: "",
        display: false,
        comment: "",
        prev: "",
        allow_order: 0,
        allow_packing: 0,
        allow_order_prev: 0,
        allow_packing_prev: 0,
        show_order_entry: false,
        show_packing_entry: false,
        show_status: true,
        show_once_check: true,
        action: null
      }

    };

  },

  methods: {

    changeState(status) {
      this.creditDetails.action = 'status_change';
      this.creditDetails.show_once_check = false;
      this.creditDetails.status = status;
      this.creditDetails.display = true;
    },

    saveUpdate() {
      if (this.creditDetails.action === 'status_change') {
        if (!this.creditDetails.comment || this.creditDetails.comment === "") {
          frappe.msgprint("Please Enter The Reason/Comment For The Updation");
          return;
        }
        if (this.creditDetails.prev === this.creditDetails.status) {
          frappe.msgprint("Can't Change the Status Of Same");
          return;
        }
      }
      if (this.creditDetails.action === 'allow_once') {
        if (this.creditDetails.allow_order === this.creditDetails.allow_order_prev &&
            this.creditDetails.allow_packing === this.creditDetails.allow_packing_prev) {
          frappe.msgprint("Please Toggle Any Allow Once Option");
          return;
        }
      }
      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.customer_status_update_log.customer_status_update_log.check_pending_req',
        args: {
          'customer': cur_frm.doc.name
        },
        callback: (response) => {
          response = response.message;
          if (response.status === 200 && response.pending_req && response.has_permission) {
            frappe.confirm(
              `${cur_frm.doc.name} already has pending update request ${response.pending_req}<br>Do You Want to Reject That ?`,
              () => {
                this.cancelPendingRequest(response.pending_req);
              },
              () => {}
            );
          } else if (response.pending_req) {
            frappe.msgprint(`${cur_frm.doc.name} already has pending update request`);
          } else {
            this.createUpdateRequest();
          }
        }
      });
    },

    cancelPendingRequest(req_id) {
      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.customer_status_update_log.customer_status_update_log.update_request_status',
        args: {
          reqId: req_id,
          action: 'reject'
        },
        callback: (response) => {
          if (response.message && response.message.status === 200) {
            this.createUpdateRequest();
          }
        }
      });
    },

    createUpdateRequest() {
      this.creditDetails.display = false;
      this.creditDetails.prev = this.creditDetails.status;
      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.customer_status_update_log.customer_status_update_log.create_status_update_request',
        args: {
          'customer': cur_frm.doc.name,
          'cur_status': cur_frm.doc.customer_credit_status,
          'update_status': this.creditDetails.status,
          'reason': this.creditDetails.comment,
          'action': this.creditDetails.action,
          'allow_order': this.creditDetails.allow_order === true ? 1 : 0,
          'allow_packing': this.creditDetails.allow_packing === true ? 1 : 0
        },
        callback: (response) => {
          if (response.message.status === 201) {
            cur_dialog.hide();
            cur_frm.reload_doc();
            if (response.message.has_permission) {
              frappe.msgprint("The Request is Directly Approved");
            } else {
              frappe.msgprint("Status Update Requested");
            }
          }
        }
      });
    },

    cancelUpdate() {
      this.creditDetails.show_once_check = true;
      this.creditDetails.show_status = true;
      this.creditDetails.comment = "";
      this.creditDetails.status = this.creditDetails.prev;
      this.creditDetails.allow_order = this.creditDetails.allow_order_prev;
      this.creditDetails.allow_packing = this.creditDetails.allow_packing_prev;
      this.creditDetails.display = false;
      this.creditDetails.action = null;
    },

    get_data() {
      if (this.creditDetails.display) {
        return this.creditDetails.prev;
      }
      return this.creditDetails.status;
    },

    toggleAllowOnce() {
      this.creditDetails.action = 'allow_once';
      this.creditDetails.show_status = false;
      this.creditDetails.display = true;
    },

    checkShowOnceEntry(status) {
      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.customer_credit_settings.customer_credit_settings.check_allow_once_entry',
        args: {
          'status': status
        },
        freeze: true,
        callback: (response) => {
          if (response.message) {
            this.creditDetails.show_order_entry = response.message.order;
            this.creditDetails.show_packing_entry = response.message.packslip;
          }
        }
      });
    },

    calculateField() {
      frappe.call({
        method: "essdee_sales.sales_order_management.doctype.customer.customer.calculate_customer_status",
        args: {
          "customer": cur_frm.doc.name
        },
        callback: (response) => {
          cur_dialog.hide();
          if (response.message.status === 201) {
            cur_frm.reload_doc();
            if (response.message.has_permission) {
              frappe.msgprint("Status Updated Directly");
            } else {
              frappe.msgprint("Status Update Requested");
            }
          } else if (response.message.status === 200) {
            frappe.msgprint("Customer Status Not Changed");
          }
        }
      });
    }
  },

  mounted() {
    this.creditDetails.status = this.credit_status;
    this.creditDetails.prev = this.creditDetails.status;
    this.creditDetails.allow_order = this.allow_order === 1 ? true : false;
    this.creditDetails.allow_order_prev = this.creditDetails.allow_order;
    this.creditDetails.allow_packing = this.allow_packing === 1 ? true : false;
    this.creditDetails.allow_packing_prev = this.creditDetails.allow_packing;
    this.checkShowOnceEntry(this.credit_status);
  }

};
</script>

<style scoped>

.form-control{
    min-width: 3rem;
    max-width: max-content;
}

input[type="checkbox"]{
    min-width: 1rem !important;
    height: 1rem !important;
}

.form-control:hover{
    cursor: pointer;
}

.flex_col{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.flex_row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
}

svg{
    width: 20px;
    height: 20px;
}

.btn{
    text-align: center;
    max-width: fit-content;
}

.main{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

</style>
<template>
    <div class="container">
        <button class="btn btn-primary" @click="makeMigrate()">Make Migration</button>
        <br>
        <hr>
        <h4>Migration Details</h4>
        <table class="table table-bordered rounded" v-if="migrationData.length != 0">
            <thead class="grid-heading-row">
                <tr>
                    <th>S.No</th>
                    <th>Doctype</th>
                    <th>Status</th>
                    <th>Last Modification</th>
                    <th>Error Log</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="i,index in migrationData" :key="index">
                    <td>{{index+1}}</td>
                    <td>{{i['doctype']}}</td>
                    <td>{{i['status']}}</td>
                    <td>{{frappe.format(i['last_modification'], { fieldtype: 'Date' })}}</td>
                    <td> 
                        <button v-if="i['error_log']" class="btn btn-danger" @click.prevent="openErrorLog(i['error_log'])">View Error</button>
                    </td>
                    <td>
                        <input type="checkbox" class="form-control" v-model="i['check']" :disabled="i['status'] == 'Running'">
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-else>
            <nothing-to-display/>
        </div>
    </div>
</template>

<script>

import NothingToDisplay from './NothingToDisplay.vue';

export default {
    name : "SMSMigrationViewer",
    components : {
        NothingToDisplay
    },
    data() {
        return {
            migrationData : [],
        }
    },
    methods: {
        
        subscribeEvent(){
            frappe.realtime.on("sms_migration",(data)=>{
                this.fetchDetails();
            })
        },

        unsubscribeEvent(){
            frappe.realtime.off("sms_migration");
        },

        fetchDetails(){
            frappe.call({
                method : "essdee_sales.sales_order_management.doctype.sms_migration.sms_migration.get_migration_details",
                callback : (response)=>{
                    this.migrationData = [];

                    for(let i=0; i<response.message.length; i++){
                        this.migrationData.push({
                            "doctype" : response.message[i]['doctype'],
                            "status" : response.message[i]['status'],
                            "last_modification" : response.message[i]['last_modification'],
                            "error_log" : response.message[i]['error_log'],
                            "check" : false
                        })
                    }
                }
            })
        },

        openErrorLog(err_log){
            frappe.set_route("Form","Error Log", err_log);
        },

        makeMigrate(){
            let doctype_list = [];
            for(let i=0;i<this.migrationData.length;i++){
                if(this.migrationData[i]['check'] === true){
                    doctype_list.push(this.migrationData[i]['doctype']);
                }
            }
            frappe.call({
                "method" : "essdee_sales.utils.sms_migration.make_sms_migrations",
                "args" : {
                    "doc_list" : doctype_list
                },
                "freeze" : true,
                "freeze_msg" : `Migrating`,
	        	"callback" : ()=>{
	        		frappe.show_alert({
	        			"message" : "Migration Started",
	        			"indicator" : "green",
	        		}, 5)
	        	}
            });
        }
    },
    mounted() {
        this.subscribeEvent();
        this.fetchDetails();
    },
    unmounted() {
        this.unsubscribeEvent();
    },
}
</script>

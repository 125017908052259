<template>
    <div class="container">
        <h3>Original Pending Items</h3>
        <div v-if="itemDisplayData.length>0">
            <table>
                <tr v-for="(items) in itemDisplayData" :key="items"  >
                    <table class="table table-bordered" v-if="items.length!=0">
                        <thead class="grid-heading-row">
                            <tr>
                                <th>Item Name</th>
                                <th v-for="key in items[0]['attributes']" :key="key">{{key}}</th>
                                <template v-if="items[0]['primary_attribute']">
                                    <th v-for="key in items[0]['primary_attribute_values']" :key="key">{{key}}</th>
                                </template>
                                <template v-if="!items[0]['primary_attribute']">
                                    <th>Quantity</th>
                                </template>
                                <th class="fixed">Multiplier</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item1) in items" :key="item1">
                                <td>{{item1.item_name}}</td>
                                <td v-for="key in items[0]['attributes']" :key="key">{{item1['attribute_values'][key]}}</td>
                                <template v-if="item1['primary_attribute']">
                                    <td v-for="key in items[0]['primary_attribute_values']" :key="key">{{(item1['qty'][key] && item1['qty'][key] != 0)? item1['qty'][key] : '--'}}</td>
                                </template>
                                <template v-if="!item1['primary_attribute']">
                                    <td>{{item1['qty']['default']}}</td>
                                </template>
                                <td>{{item1.multiplier}}</td>
                            </tr>
                        </tbody>
                    </table>
                </tr>
            </table>
        </div>
        <div v-else>
            <nothing-to-display/>
        </div>
        <hr>
        <div>
            <h3>Update Data</h3>
            <div v-if="itemEditData.length>0">
                <table>
                    <tr v-for="(items) in itemEditData" :key="items"  >
                        <table class="table table-bordered" v-if="items.length!=0">
                            <thead class="grid-heading-row">
                                <tr>
                                    <th>Item Name</th>
                                    <th v-for="key in items[0]['attributes']" :key="key">{{key}}</th>
                                    <template v-if="items[0]['primary_attribute']">
                                        <th v-for="key in items[0]['primary_attribute_values']" :key="key">{{key}}</th>
                                    </template>
                                    <template v-if="!items[0]['primary_attribute']">
                                        <th>Quantity</th>
                                    </template>
                                    <th class="fixed">Multiplier</th>
                                    <th class="fixed">Selected</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item1) in items" :key="item1">
                                    <td>{{item1.item_name}}</td>
                                    <td v-for="key in items[0]['attributes']" :key="key">
                                        {{item1['attribute_values'][key]}}
                                    </td>
                                    <template v-if="item1['primary_attribute']">
                                        <td v-for="key in items[0]['primary_attribute_values']" :key="key">
                                            <input type="number" v-model="item1['qty'][key]" class="form-control" :disabled="true" @input="makeDirty()">
                                        </td>
                                    </template>
                                    <template v-if="!item1['primary_attribute']">
                                        <td>
                                            <input type="number" v-model="item1['qty']['default']" class="form-control" :disabled="isEditable()" @input="makeDirty()">
                                        </td>
                                    </template>
                                    <td>
                                        {{item1.multiplier}}
                                    </td>
                                    <td>
                                        <input type="checkbox" class="form-control" :disabled="isEditable()" v-model="item1.selected" @change="makeDirty()">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </tr>
                </table>
            </div>
            <div v-else>
                <nothing-to-display/>
            </div>
        </div>
    </div>
</template>

<script>
import NothingToDisplay from './NothingToDisplay.vue';
import { combineTableData, cloneData } from '../Utils';

export default {
    name: 'PendingOrderUpdateReq',
    
    props: ['order','pending','update'],

    components: {
        NothingToDisplay
    },
    data() {
        return {
            itemDisplayData: [],
            itemEditData: []
        };
    },
    methods: {
        makePendingOrderItem(order) {
            frappe.call({
                method: 'essdee_sales.sales_order_management.doctype.order.order.get_pending_order_details',
                args: {
                    order: order
                },
                callback: (response) => {
                    response = response.message;
                    if (response.status === 200) {
                        this.makeDisplayableData(response.data);
                    }
                }
            });
        },

        makeDisplayableData(data) {
            for (let i = 0; i < data.length; i++) {
                let ele1 = data[i];
                let ele2 = cloneData(ele1);
                ele2['selected'] = false;
                combineTableData(ele1, this.itemDisplayData);
                combineTableData(ele2, this.itemEditData);
            }
            this.sortByItemName();
        },

        sortByItemName() {
            this.itemDisplayData.sort((a, b) => a[0]['item_name'].localeCompare(b[0]['item_name']));
            this.itemEditData.sort((a, b) => a[0]['item_name'].localeCompare(b[0]['item_name']));
        },

        createUpdateReq() {
            let rep = this.validateUpdateReq();
            if (rep[0]) {
                let data = [];
                for (let i = 0; i < this.itemEditData.length; i++) {
                    for (let j = 0; j < this.itemEditData[i].length; j++) {
                        this.itemEditData[i][j]['row1'] = i;
                        this.itemEditData[i][j]['col1'] = j;
                        data.push(this.itemEditData[i][j]);
                    }
                }
                return JSON.stringify({
                    valid: true,
                    data: data
                });
            }
            return JSON.stringify({
                valid: false,
                data: rep[1]
            });
        },

        isEditable() {
            if (cur_frm.doc.status && cur_frm.doc.status !== 'Draft') {
                return true;
            }
            return false;
        },

        validateUpdateReq() {
            let valid = true;
            let error_str = "<br>";
            for (let i = 0; i < this.itemDisplayData.length; i++) {
                for (let j = 0; j < this.itemDisplayData[i].length; j++) {
                    let obj = this.itemDisplayData[i][j];
                    let updated_obj = this.itemEditData[i][j];
                    if (obj['primary_attribute']) {
                        for (let k = 0; k < obj['primary_attribute_values'].length; k++) {
                            let key = obj['primary_attribute_values'][k];
                            let v1 = Number(obj['qty'][key]);
                            let v2 = Number(updated_obj['qty'][key]);
                            if (cur_frm.doc.type === 'Edit' && v1 > v2) {
                                error_str += "The Quantity for " + key + " in Item " + obj['item_name'] + " Cannot be Less than the Original Quantity<br>";
                                valid = false;
                            } else if (cur_frm.doc.type === 'Cancel' && v1 < v2) {
                                error_str += "The Quantity for " + key + " in Item " + obj['item_name'] + " Cannot be Greater than the Original Quantity<br>";
                                valid = false;
                            }
                        }
                    } else {
                        let v1 = Number(obj['qty']['default']);
                        let v2 = Number(updated_obj['qty']['default']);
                        if (cur_frm.doc.type === 'Edit' && v1 > v2) {
                            error_str += "The Quantity for Item " + obj['item_name'] + " Cannot be Less than the Original Quantity<br>";
                            valid = false;
                        } else if (cur_frm.doc.type === 'Cancel' && v1 < v2) {
                            error_str += "The Quantity for Item " + obj['item_name'] + " Cannot be Greater than the Original Quantity<br>";
                            valid = false;
                        }
                    }
                }
            }
            return [valid, error_str];
        },

        makeDisplayableUpdateData(pending, update) {
            for (let i = 0; i < pending.length; i++) {
                let ele1 = pending[i];
                let ele2 = update[i];
                combineTableData(ele1, this.itemDisplayData);
                combineTableData(ele2, this.itemEditData);
            }
        },

        makeDirty() {
            cur_frm.dirty();
        }
    },
    
    mounted() {
        if (!this.$props.pending && !this.$props.update) {
            this.makePendingOrderItem(this.$props.order);
        } else {
            this.makeDisplayableUpdateData(JSON.parse(this.$props.pending), JSON.parse(this.$props.update));
        }
    }
};
</script>


<style scoped>

table{
    width: 100%;
}

input[type='number']{
    max-width: 100px;
}
</style>
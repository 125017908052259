<template>
  <div class="container">
    <div class="row">
      <div v-for="attr in attrList" :key="attr" class="col">
        <div class="address-box" style="max-width: 250px; min-width: 250px">
          <div class="d-flex justify-between">
            <h5>{{ attr.attribute_name }}</h5>
            <a
              @click.prevent="
                openItemAttribute(attr.doctype, attr.attr_value_link)
              "
              v-if="attr.attr_value_link"
              class="btn btn-default"
            >
              Edit
            </a>
          </div>
          <p v-for="attr_value in attr.attr_values" :key="attr_value">
            {{ attr_value.attribute_value }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AttributeList",
  props: ["attrList"],
  methods: {
    openItemAttribute: (doctype, name) => {
      frappe.set_route("Form", doctype, name);
    },
  },
};
</script>
<template>
  <div ref="root">
    <div>
        <div class="item_select col-md-5"></div>
    </div>
    <div class="table-responsive">
        <h5>Items</h5>
        <table v-if="itemDisplayData.length!=0">
            <tr v-for="(items,index1) in itemDisplayData" :key="items"  >
                <table class="table table-bordered" v-if="items.length!=0">
                    <thead class="grid-heading-row">
                          <tr>
                              <th>Item Name</th>
                              <th v-for="sec in items[0]['attributes']" :key="sec">{{sec}}</th>
                              <template v-if="items[0].primary_attribute">
                                  <th v-for="pri in items[0]['primary_attribute_values']" :key="pri">{{pri}}</th>
                              </template>
                              <th>Comments</th>
                              <template v-if="!items[0]['primary_attribute']">
                                  <th>Quantity</th>
                              </template>
                              <th class="fixed">Multiplier</th>
                              <th class="actions">Actions</th>
                          </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item1,index2) in items" :key="item1" >
                            <td>{{item1.item_name}}</td>
                            <td v-for="sec in items[0]['attributes']" :key="sec">{{item1['attribute_values'][sec]}}</td>
                            <template v-if="item1['primary_attribute']">
                                <td v-for="pri in items[0]['primary_attribute_values']" :key="pri">{{(item1['qty'][pri] && item1['qty'][pri]!=0)? item1['qty'][pri] : '--'}}</td>
                            </template>
                            <td>{{item1.comment}}</td>
                            <template v-if="!item1['primary_attribute']">
                                <td>{{item1['qty']['default']}}</td>
                            </template>
                            <td>{{item1['multiplier']}}</td>
                            <td class="d-flex flex-row">
                                    <div v-html="frappe.utils.icon('delete', 'md')" class="cursor" @click="removeOrderItem(index1,index2)"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </tr>
        </table>
        <nothing-to-display v-else/>
    </div>
    <div v-if="itemCreationData.display" class="table-responsive rounded-3">
        <h5>New Item</h5>
        <table class="table table-bordered">
           <thead>
               <tr class="grid-heading-row">
                   <th>Item Name</th>
                   <th v-for="sec_val in itemCreationData.item_details['attributes']" :key="sec_val">{{sec_val}}</th>
                   <template v-if="itemCreationData.item_details['primary_attribute']">
		    		  	<th v-for="pri_val in itemCreationData.item_details['attribute_values'][itemCreationData.item_details['primary_attribute']]" :key="pri_val">{{pri_val}}</th>
                   </template>
                   <th>Comments</th>
                   <th v-if="!itemCreationData.item_details['primary_attribute']">Quantity</th>
                   <th class="fixed">Multiplier</th>
               </tr>
           </thead>
           <tbody>
               <tr>
                   <td>{{ itemCreationData.item_name }}</td>
                   <td v-for="sec_val in itemCreationData.item_details['attributes']" :key="sec_val" >
                       <select name="attr_select" class="form-control" v-model="newItemDetails.data['attribute_values'][sec_val]">
                           <option :value="null" selected>Select a {{ sec_val }}</option>
                           <option :value="value" v-for="value in itemCreationData.item_details['attribute_values'][sec_val]" :key="value">
                           {{ value }}
                           </option>
                       </select>
                   </td>
                   <td v-for="pri_val in itemCreationData.item_details['attribute_values'][itemCreationData.item_details['primary_attribute']]" :key="pri_val"> 
                       <input class="form-control editable-input" type="number" v-model="newItemDetails.data['qty'][pri_val]"/>
                   </td>
                   <td>
                       <input class="form-control" type="text" v-model="newItemDetails.data.comment">
                   </td>
                   <td v-if="!itemCreationData.item_details['primary_attribute']">
                       <input class="form-control" type="number" v-model="newItemDetails.data['qty']['default']" />
                   </td>
                   <td>
                       <input class="form-control" type="number" v-model="newItemDetails.data.multiplier"/>
                   </td>
               </tr>
           </tbody>
        </table>
        <button class="btn-success border-none rounded mb-3" @click.prevent="addItemToTable()">
            Add Item
        </button>
    </div>
  </div>
</template>

<script>

import { combineTableData, validateItemData } from '../Utils';
import NothingToDisplay from './NothingToDisplay.vue';

export default {
  name: 'AdditionalOrderItem',
  props: ['dialog'],

  components: {
    NothingToDisplay
  },

  data() {
    return {
      itemCreationData: {
        display: false,
        item_name: '',
        item_details: {}
      },
      root: null,
      sample_doc: {},
      item_select: null,
      newItemDetails: {
        data: {}
      },
      itemDisplayData: []
    };
  },

  methods: {

    addItem(newItem) {
      combineTableData(newItem, this.itemDisplayData);
      if (this.item_select) {
        this.item_select.set_value(null);
      }
    },

    changeData(item_name) {
      this.itemCreationData.display = false;
      this.itemCreationData.item_name = '';
      this.itemCreationData.item_details = null;
      this.newItemDetails.data = {};
      if (item_name === '') {
        return;
      }
      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.item.item.get_item_details',
        args: {
          item_name: item_name
        },
        callback: (response) => {
          response = response.message;
          if (response.status === 200) {
            this.itemCreationData.display = true;
            this.itemCreationData.item_name = item_name;
            this.itemCreationData.item_details = response.data;
            this.makeItemCreationData(response.data);
          }
        }
      });
    },

    makeItemCreationData(data) {

      this.newItemDetails.data['item_name'] = data.item_name;
      this.newItemDetails.data['primary_attribute'] = null;
      this.newItemDetails.data['primary_attribute_values'] = [];
      this.newItemDetails.data['qty'] = {};
      this.newItemDetails.data['attributes'] = [];
      this.newItemDetails.data['attribute_values'] = {};
      if (data['primary_attribute']) {
        let prim = data['primary_attribute'];
        data['attribute_values'][prim].forEach(element => {
          this.newItemDetails.data['qty'][element] = 0;
          this.newItemDetails.data['primary_attribute_values'].push(element);
        });
        this.newItemDetails.data['primary_attribute'] = prim;
      }
      data['attributes'].forEach(ele => {
        this.newItemDetails.data['attributes'].push(ele);
        this.newItemDetails.data['attribute_values'][ele] = null;
      });
      this.newItemDetails.data['qty']['default'] = 0;
      this.newItemDetails.data.comment = '';
      this.newItemDetails.data.multiplier = 1;
      
    },

    addItemToTable() {
      let tempData = this.newItemDetails.data;
      if (!validateItemData(tempData, 'input')) return;
      this.addItem(tempData);
    },

    removeOrderItem(index1, index2) {
      let items = this.itemDisplayData[index1];
      if (index2 >= 0 && index2 < items.length) {
        items.splice(index2, 1);
        if (items.length === 0) {
          this.itemDisplayData.splice(index1, 1);
        }
      }
    },

    makeSelectField() {
      let el = this.$refs['root'];
      $(el).find('.item_select').html('');
      this.item_select = frappe.ui.form.make_control({
        parent: $(el).find('.item_select'),
        df: {
          fieldtype: 'Link',
          fieldname: 'item_select',
          label: 'Select Item',
          options: 'Item',
          onchange: () => {
            if (this.item_select['last_value'] !== this.item_select['value']) {
              this.changeData(this.item_select.get_value());
            }
          }
        },
        doc: this.sample_doc,
        render_input: true
      });
    },

    get_data() {
      return JSON.stringify(this.itemDisplayData);
    }
  },

  mounted() {
    this.makeSelectField();
  }
};
</script>


<style scoped>
input {
  max-width: 5rem;
  align-self: center;
}

table{
    width: 100%;
}

.cursor{
    cursor: pointer;
}

.bg_unable{
    background-color: grey;
    color: white;
}

</style>

<template>
    <div>
        <div class="container mt-4 p-2" v-if="itemDisplayData.display">
            <h2 style="padding:0px;margin:0px;">Picklisted Items</h2>
            <div v-for="customer in Object.keys(itemDisplayData['displayableData'])" :key="customer" class="flex_col">
                <table v-if="itemDisplayData['displayableData'][customer].length!=0">
                    <div class="m-2 flex_row">
                        <h4>{{customer}}</h4>
                        <p class="align_bottom">{{itemDisplayData['displayableData'][customer][0][0]['sales_partner']}}</p>
                    </div> 
                    <tr v-for="(items) in itemDisplayData['displayableData'][customer]" :key="items"  >
                        <table class="table table-bordered" v-if="items.length!=0">
                            <thead class="grid-heading-row">
                                <tr>
                                    <th class="fixed">Order Date</th>
                                    <th>Order Name</th>
                                    <th>Item Name</th>
                                    <th v-for="key in items[0]['attributes']" :key="key">{{key}}</th>
                                    <template v-if="items[0]['primary_attribute']">
                                        <th v-for="key in items[0]['primary_attribute_values']" :key="key">{{key}}</th>
                                    </template>
                                    <template v-if="!items[0]['primary_attribute']">
                                        <th>Quantity</th>
                                    </template>
                                    <th class="fixed">Multiplier</th>
                                    <th class="actions">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item1) in items" :key="item1" :class="item1.isPickListed? 'item_selected' : ''">
                                    <td>{{frappe.format(item1.order_date, { fieldtype: 'Date' })}}</td>
                                    <td>{{item1.order}}</td>
                                    <td>{{item1.item_name}}</td>
                                    <td v-for="key in items[0]['attributes']" :key="key">{{item1['attribute_values'][key]}}</td>
                                    <template v-if="items[0]['primary_attribute']">
                                        <td v-for="key in items[0]['primary_attribute_values']" :key="key">{{(item1['qty'][key] && item1['qty'][key] !=0)? item1['qty'][key] : '--'}}</td>
                                    </template>
                                    <template v-if="!items[0]['primary_attribute']">
                                        <td>{{item1['qty']['default']}}</td>
                                    </template>
                                    <td>{{item1.multiplier}}</td>
                                    <td class="actions">
                                        <div class="ml-1 cursor">
                                            <button class="btn btn-success" @click="makePickList(customer,item1['order'],item1['row'],item1['col'])" >Add</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </tr>
                </table>
            </div>
            <div v-if="Object.keys(itemDisplayData['displayableData']).length==0 && !pickListData.display">
                <nothing-to-display/>
            </div>
        </div>
        <packing-slip-create-update 
            :pickListData="pickListData" 
            :packSlipData="packSlipData"
            :addToPackList="addToPackList"
            :removeFromPackList="removeFromPackList"
            :addPackListItem="addPackListItem"
            :finishPackingSlip="finishPackingSlip"
            :clearPackingSlip="clearPackingSlip"
            :makeInstructionRead="makeInstructionRead"
            :action="'create'"

            v-if="pickListData.display"/>
    </div>
</template>

<script>
import NothingToDisplay from '../Components/NothingToDisplay.vue';
import PackingSlipCreateUpdate from '../Components/PackingSlipCreateUpdate.vue';
import { combineTableData, cloneData, validatePackingSlip } from '../Utils';

export default {

  name: 'PickListManagement',

  components: {
    NothingToDisplay,
    PackingSlipCreateUpdate
  },

  data() {

    return {

      itemDisplayData: {
        data: {},
        display: false,
        displayableData: {}
      },

      pickListData: {
        data: {},
        display: false,
        displayableData: {},
        customer: "" 
      },

      packSlipData: {
        data: {}, // sus
        display: false, // sus
        packItems: [],
        displayableData: [],
        transport: "",
        packSlipComment: "",
        disableCarton: 0,
        standing_instructions: "",
        standing_instructions_display: false,
        instruction_read: false,
        address: "",
        price_list: "",
        schemes: [],
        sales_partner: "",
        dispatch_location : "",
        order_type : "Primary"
      }

    };

  },

  methods: {

    addItem(customer, newItem) {
      if (!this.itemDisplayData.displayableData[customer]) {
        this.itemDisplayData.displayableData[customer] = [];
      }
      combineTableData(newItem, this.itemDisplayData.displayableData[customer]);
    },

    addPickListItem(order, newItem) {
      if (!this.pickListData.displayableData[order]) {
        this.pickListData.displayableData[order] = [];
      }
      combineTableData(newItem, this.pickListData.displayableData[order]);
    },

    addPackListItem(newItem) {
      combineTableData(newItem, this.packSlipData.displayableData);
      combineTableData(cloneData(newItem), this.packSlipData.packItems);
    },

    makeDisplayableData(data) {
      const tempData = JSON.parse(JSON.stringify(data));
      const result = {};
      for (const item of tempData) {
        if (!result[item['customer']]) {
          result[item['customer']] = {};
        }
        if (!result[item['customer']][item['order']]) {
          result[item['customer']][item['order']] = [];
        }
        result[item['customer']][item['order']].push(item);
      }
      return result;
    },

    displayData(data) {
      this.itemDisplayData.data = data;
      for (const customer in data) {
        for (const order in data[customer]) {
          data[customer][order].forEach(ele => {
            this.addItem(customer, ele);
          });
        }
      }
      this.itemDisplayData.display = true;
    },

    makePickListDisplayable(customer, order, row, col) {
      this.pickListData.customer = customer;
      for (const keys in this.pickListData.data[customer]) {
        this.pickListData.data[customer][keys].forEach(ele => {
          if (ele['order'] == order && ele['row'] == row && ele['col'] == col) {
            ele['isSelected'] = true;
            ele['carton'] = 0;
            this.addPackListItem(ele);
          } else {
            ele['isSelected'] = false;
          }
          this.addPickListItem(ele['order'], ele);
        });
      }
      this.pickListData.display = true;
      this.itemDisplayData.display = false;
    },

    beforeUnloadListener(event) {
      event.preventDefault();
    },

    addUnloadEvent() {
      window.addEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
    },

    removeUnloadEvent() {
      window.removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
    },

    makePickList(customer, order, row, col) {
      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.order.order.get_pick_list_details',
        args: {
          "selected_item": JSON.stringify({
            customer: customer
          })
        },
        freeze: true,
        callback: (response) => {
          response = response.message;
          if (response.status == 200) {
            this.addUnloadEvent();
            this.pickListData.data = this.makeDisplayableData(response.data);
            this.packSlipData.standing_instructions = response.standing_instruction;
            this.packSlipData.standing_instructions_display = response.standing_instruction_display;
            this.packSlipData.sales_partner = response.sales_partner;
            this.packSlipData.customer = customer;
            this.makePickListDisplayable(customer, order, row, col);
          }
        }
      });
    },

    fetchDetails() {
      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.order.order.get_pick_list_details',
        freeze: true,
        callback: (response) => {
          response = response.message;
          if (response.status == 200) {
            const partialData = this.makeDisplayableData(response.data);
            this.displayData(partialData);
          }
        }
      });
    },

    addToPackList(item) {
      item['isSelected'] = true;
      item['carton'] = 0;
      item['scheme'] = 0;
      this.addPackListItem(item);
    },

    finishPackingSlip() {
      if (validatePackingSlip(this.packSlipData)) {
        const packListConfirm = new frappe.ui.Dialog({
          fields: [
            {
              label: 'Pack List Confirm Html',
              fieldname: "pack_list_confirm_html",
              fieldtype: 'HTML'
            }
          ],
          primary_action: () => {
            this.savePackingSlip();
            packListConfirm.hide();
          },
          primary_action_label: 'Save',
          secondary_action: () => {
            packListConfirm.hide();
          },
          secondary_action_label: 'Continue Editing Packing Slip'
        });
        packListConfirm.show();
        const packingSlipReport = new frappe.essdee_sales.OMS.ui.PackingSlipReport(
          packListConfirm.fields_dict.pack_list_confirm_html.wrapper,
          JSON.stringify(this.makeConfirmBoxData())
        );
        packListConfirm.$wrapper.find('.modal-dialog').css("min-width", "max-content");
      }
    },

    savePackingSlip() {
      const data = [];
      for (let i = 0; i < this.packSlipData.packItems.length; i++) {
        for (let j = 0; j < this.packSlipData.packItems[i].length; j++) {
          let item = this.packSlipData.packItems[i][j];
          if (item['order_type'] == 'Secondary') {
            this.packSlipData.order_type = 'Secondary';
          }
          item['row1'] = i;
          item['col1'] = j;
          data.push(item);
        }
      }
      this.removeUnloadEvent();
      frappe.call({
        method: 'essdee_sales.sales_order_management.doctype.packing_slip.packing_slip.save_packing_slip',
        args: {
          pack_slip_data: JSON.stringify(data),
          customer: this.pickListData.customer,
          transport: this.packSlipData.transport,
          disable_carton: this.packSlipData.disableCarton ? 1 : 0,
          comments: this.packSlipData.packSlipComment,
          address: this.packSlipData.address,
          price_list: this.packSlipData.price_list,
          schemes: JSON.stringify(this.packSlipData.schemes),
          dispatch_location : this.packSlipData.dispatch_location,
          packing_team : this.packSlipData.packing_team,
          deliver_to : this.packSlipData.deliver_to,
          order_type : this.packSlipData.order_type
        },
        freeze: true,
        callback: (response) => {
          if (response.message.status == 200) {
            frappe.show_alert({
              message: __('Packing Slip Created'),
              indicator: 'green'
            }, 10);
            frappe.set_route('Form', 'Packing Slip', response.message.pack_slip);
          }
        }
      });
    },

    removeFromPackList(index1, index2) {
      this.packSlipData.displayableData[index1][index2].isSelected = false;
      this.packSlipData.displayableData[index1].splice(index2, 1);
      this.packSlipData.packItems[index1].splice(index2, 1);
      if (this.packSlipData.displayableData[index1].length == 0) {
        this.packSlipData.displayableData.splice(index1, 1);
        this.packSlipData.packItems.splice(index1, 1);
      }
    },

    makeConfirmBoxData() {
      return {
        customer: this.pickListData.customer,
        address: this.packSlipData.address,
        sales_partner: this.packSlipData.sales_partner,
        pack_slip: "",
        created_date_time: frappe.datetime.nowdate(),
        transport: this.packSlipData.transport,
        array_data: this.packSlipData.packItems,
        comments: this.packSlipData.packSlipComment,
        disable_carton_slip: this.packSlipData.disableCarton,
        is_cancelled: false,
        price_list: this.packSlipData.price_list,
        schemes: this.packSlipData.schemes,
        dispatch_from : this.packSlipData.dispatch_location,
        deliver_to : this.packSlipData.deliver_to
      };
    },

    makeInstructionRead() {
      this.packSlipData.instruction_read = !this.packSlipData.instruction_read;
    },

    clearPackingSlip() {
      frappe.confirm('Are you sure you want to Clear Packing Slip?',
        () => {
          this.removeUnloadEvent();
          const route = frappe.get_route();
          if (route[route.length - 1] === '1') {
            frappe.set_route('order-process');
          } else {
            frappe.set_route('order-process/1');
          }
        }, () => { });
    }

  },

  mounted() {
    this.fetchDetails();
  },

  beforeDestroy() {
    this.removeUnloadEvent();
  }
  
};
</script>


<style scoped>

.flex_row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.flex_col{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.align_bottom{
    align-self: flex-end;
    margin-left: 1rem;
}
input{
    min-width: fit-content;
}

th,td{
    align-items: flex-start;
}

.heading{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.btn{
    min-width: 4rem;
    max-width: max-content;
}

.pack-cus-details{
    padding: 5px;
    margin: 15px;
}

.panel-heading{
    font-size: 0.9rem;
    font-weight: 700;
    padding-bottom:2px ;
}

.gap-min{
    gap: 0.1rem;
}

.form-control-input{
    min-width: 40px !important;
}

.panel-content{
    background-color: rgb(255, 255, 255);
    padding: 4px;
    border-radius: 5px;
}

.form-min{
    max-width: 4rem;
}

.standard-instruction{
    border: 1px solid black;
    border-radius: 10px;
    padding: 1rem;
}

.comment-box{
    margin: 0%;
    width: 35vw;
    padding: 1px;
    min-width: 500px;
}
</style>
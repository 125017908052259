<template>
  <div class="container row_flex selected" ref="root">
    <div class="mt-2">
      <h4 style="font-weight: bolder">Item Price Management</h4>
    </div>
    <div class="mt-2 border-rounded">
      <div class="md-5">
        <div class="price_list_select"></div>
        <div class="item_select"></div>
        <div class="price_list_uom">
          <label for="default_unit_of_measure" class="control-label">Default UOM</label>
          <input type="text" class="form-control" name="default_unit_of_measure" :value="item_price_data.defaultUom"
            readonly />
        </div>
        <div v-show="item_price_data.readOnly">
          <div class="uom_select"></div>
        </div>
        <div v-show="item_price_data.readOnly && getPriceList">
          <div class="d-flex flex-col justify-content-center align-items-center">
            <div>
              <button class="btn btn-primary m-2 d-block " @click="createAndDownloadXLFile()">Download Price(XLSX Format)</button>
            </div>
            <div class="d-flex flex-row flex-wrap p-5">
              <label class="form-label d-block" for="customFile">Upload File:</label>
              <input @change="makeFileUpload" type="file" :multiple="false" class="form-control d-flex w-fit d-block m-2"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="customFile" />
              <button class="btn btn-secondary d-block m-2" v-if="curr_price_file"
                @click.prevent="makeBulkUpload()"
              >Make Upload</button>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-split" v-if="item_price_data.display && !item_price_data.readOnly">
        <button class="btn btn-danger m-2" @click="clearSelectedItem()">
          Clear
        </button>
        <button v-if="checkForShowPermission"  class="btn btn-success m-2" @click="saveItemPriceList()">
          Save
        </button>
      </div>
    </div>
    <div class="table-responsive" v-if="item_price_data.display && item_price_data['data'].length">
      <table class="table table-bordered">
        <tr v-for="row, idx1 in item_price_data['data']" :key="row">
          <br />
          <table>
            <thead>
              <tr class="grid-heading-row">
                <th class="d-inline-flex flex-row">Item Name <input v-if="item_price_data.readOnly"
                    class="form-control m-1" type="checkbox" @change="(event) => selectAllOrUnselectAll(event, idx1)">
                </th>
                <th v-for="sec in row[0]['attributes']" :key="sec">
                  {{ sec }}
                </th>
                <template v-if="row[0]['primary_attribute']">
                  <th v-for="pri in row[0]['primary_attribute_values']" :key="pri">
                    {{ pri }}
                  </th>
                </template>
                <template v-if="!row[0]['primary_attribute']">
                  <th>Price</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item, idx2 in row" :key="item">
                <td>
                  <div class="d-flex flex-row">
                    <input v-if="item_price_data.readOnly" class="form-control m-1" type="checkbox"
                      v-model="item['checked']">
                    {{ item["item_name"] }}
                  </div>
                </td>
                <td v-for="sec in row[0]['attributes']" :key="sec">
                  {{ item["attribute_values"][sec] }}
                </td>
                <template v-if="item['primary_attribute']">
                  <td v-for="pri in row[0]['primary_attribute_values']" :key="pri">
                    <input class="form-control" type="number" v-model="item['rate'][pri]"
                      :disabled="item_price_data['readOnly']" :id="`input_${idx1}${idx2}${pri}`" />
                  </td>
                </template>
                <template v-if="!item['primary_attribute']">
                  <td>
                    <input class="form-control" type="number" v-model="item['rate']['default']"
                      :disabled="item_price_data['readOnly']" :id="`input_${idx1}${idx2}default`" />
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </tr>
      </table>
    </div>
    <div v-else>
      <nothing-to-display />
    </div>
  </div>
</template>

<script>
import NothingToDisplay from "../Components/NothingToDisplay.vue";
import { combineTableData, show_success_alert, show_error_alert, addError, removeError, process_error_message } from "../Utils";

export default {
  name: "PriceListManagement",

  components: { NothingToDisplay },

  data() {
    return {
      sample_doc: {},
      price_list: null,
      item_select: null,
      display_uom: null,
      curr_price_file: null,
      file_upload: null,
      item_price_data: {
        display: false,
        data: [],
        readOnly: true,
        defaultUom: "",
        originalData: null,
      },
    };
  },

  computed: {
    getPriceList() {
      if (this.price_list && this.price_list.get_value()) {
        return true;
      }
      return false;
    },
    checkForShowPermission() {
      if(frappe.user.has_role('System Manager')) return true
      return false;
    }
  },

  methods: {

    createAndDownloadXLFile() {
      let selectedItems = new Set();
      for (let i = 0; i < this.item_price_data.data.length; i++) {
        for (let j = 0; j < this.item_price_data.data[i].length; j++) {
          if (this.item_price_data.data[i][j]['checked']) {
            selectedItems.add(this.item_price_data.data[i][j].item_name);
          }
        }
      }
      if (!this.price_list.get_value()) {
        frappe.msgprint("Select price list first");
        return;
      }
      this.get_xl(this.price_list.get_value(), Array.from(selectedItems));
    },

    makeFileUpload(event) {
      let files = event.target.files;
      if (files.length > 0) {
        this.curr_price_file = files[0];
      } else {
        this.curr_price_file = null;
      }
    },

    makeBulkUpload(){
      if(!this.price_list || !this.price_list.get_value()){
        frappe.msgprint("Please Select The Price List");
        return;
      }
      if(!this.curr_price_file){
        frappe.msgprint("Upload the Updated Price List File");
        return;
      }
      let reqBody = new FormData();
      reqBody.append('file', this.curr_price_file);
      var xhr = new XMLHttpRequest();
      xhr.open("POST", `/api/method/essdee_sales.sales_order_management.doctype.price_list.price_list.make_bulk_price_list_upload?price_list=${this.price_list.get_value()}`, true);
      xhr.setRequestHeader('X-Frappe-CSRF-Token', frappe.csrf_token)
      xhr.onload = function (success) {
        if (this.status === 200){
          frappe.msgprint("Uploading Process Initiated");
        } else {
          var data = this.response;
          let fake_xhr = {
            responseText: data,
            responseJSON: JSON.parse(data),
          };
          frappe.request.cleanup({}, JSON.parse(data))
          process_error_message(data, fake_xhr)
        }
      }
      xhr.send(reqBody);
    },

    get_xl(price_list, selected_items) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', '/api/method/essdee_sales.sales_order_management.doctype.price_list.price_list.download_xls_price_format', true);
      xhr.setRequestHeader('X-Frappe-CSRF-Token', frappe.csrf_token)
      xhr.responseType = 'arraybuffer';
      xhr.onload = function (success) {
        if (this.status === 200) {
          var blob = new Blob([success.currentTarget.response], { type: "application/xlsx" });
          var filename = ""
          var disposition = xhr.getResponseHeader('Content-Disposition');
          if (disposition && disposition.indexOf('filename') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
          }

          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
            window.navigator.msSaveBlob(blob, filename);
          } else {
            var URL = window.URL || window.webkitURL;
            var downloadUrl = URL.createObjectURL(blob);

            if (filename) {
              // use HTML5 a[download] attribute to specify filename
              var a = document.createElement("a");
              // safari doesn't support this yet
              if (typeof a.download === 'undefined') {
                window.location.href = downloadUrl;
              } else {
                a.href = downloadUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
              }
            } else {
              window.location.href = downloadUrl;
            }

            setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
          }
        }
        else {
          var dec = new TextDecoder("utf-8")
          var data = dec.decode(this.response)
          let fake_xhr = {
            responseText: data,
            responseJSON: JSON.parse(data),
          };
          frappe.request.cleanup({}, JSON.parse(data))
          process_error_message(data, fake_xhr)
        }
      };
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      xhr.send($.param({ price_list: price_list, selected_items: JSON.stringify(selected_items) }, true));
    },

    selectAllOrUnselectAll(event, index) {
      for (let i = 0; i < this.item_price_data.data[index].length; i++) {
        this.item_price_data.data[index][i]['checked'] = event.target.checked;
      }
    },

    makePriceConversion(factors) {
      if (this.item_price_data.originalData == null) {
        this.item_price_data.originalData = JSON.parse(
          JSON.stringify(this.item_price_data.data)
        );
      } else {
        this.item_price_data.data = JSON.parse(
          JSON.stringify(this.item_price_data.originalData)
        );
      }

      for (let i = 0; i < this.item_price_data.data.length; i++) {
        for (let j = 0; j < this.item_price_data.data[i].length; j++) {
          let obj = this.item_price_data.data[i][j];
          if (obj.primary_attribute) {
            for (let i = 0; i < obj.primary_attribute_values.length; i++) {
              let attr = obj.primary_attribute_values[i];
              obj.rate[attr] =
                (Number(obj.rate[attr]) / Number(factors[obj.item_name][1])) *
                Number(factors[obj.item_name][0]);
            }
          } else {
            obj.rate.default =
              (Number(obj.rate.default) / Number(factors[obj.item_name][1])) *
              Number(factors[obj.item_name][0]);
          }
        }
      }
    },

    fetchUOMConversionDetails(uom) {
      if (!this.item_price_data.defaultUom) return;
      frappe.call({
        method:
          "essdee_sales.sales_order_management.doctype.item.item.get_uom_conversion_detials",
        args: {
          uom: uom,
          default_unit_of_measure: this.item_price_data.defaultUom,
        },
        freeze: true,
        freeze_msg: "Converting ...",
        callback: (response) => {
          this.makePriceConversion(response.message);
        },
      });
    },

    createPriceListSelect() {
      let el = this.$refs['root'];
      $(el).find(".price_list_select").html("");
      this.price_list = frappe.ui.form.make_control({
        parent: $(el).find(".price_list_select"),
        df: {
          fieldtype: "Link",
          fieldname: "price_list",
          label: "Price List",
          options: "Price List",
          reqd: true,
          onchange: () => {
            if (this.price_list.get_value() && this.price_list.last_value !== this.price_list.value) {
              this.fetchItemDetails();
            }
            if (this.display_uom.get_value()) {
              this.display_uom.set_value(null);
            }

            this.curr_price_file = null;
          },
        },
        doc: this.sample_doc,
        render_input: true,
      });
    },

    createItemSelect() {
      let el = this.$refs['root'];
      $(el).find(".item_select").html("");
      this.item_select = frappe.ui.form.make_control({
        parent: $(el).find(".item_select"),
        df: {
          fieldtype: "Link",
          fieldname: "item_select",
          label: "Select Item",
          options: "Item",
          onchange: () => {
            if (this.item_select.get_value() != this.item_select['last_value']) {
              this.fetchItemDetails();
            }
          },
        },
        doc: this.sample_doc,
        render_input: true,
      });
    },

    createUOMSelect() {
      let el = this.$refs['root'];
      $(el).find(".uom_select").html("");
      this.display_uom = frappe.ui.form.make_control({
        parent: $(el).find(".uom_select"),
        df: {
          fieldtype: "Link",
          fieldname: "uom_select",
          label: "Select Display UOM",
          options: "UOM",
          onchange: () => {
            if (this.display_uom.get_value() && this.display_uom.value !== this.display_uom.last_value) {
              this.fetchUOMConversionDetails(this.display_uom.get_value());
            } else if (!this.display_uom.get_value() == null) {
              if (this.item_price_data.originalData) {
                this.item_price_data.data = JSON.parse(
                  JSON.stringify(this.item_price_data.originalData)
                );
              }
            }
          },
        },
        doc: this.sample_doc,
        render_input: true,
      });
    },

    makeDisplayableData(value) {
      value['is_checked'] = false;
      for (let i = 0; i < this.item_price_data.data.length; i++) {
        let tempData = this.item_price_data.data[i][0];

        if (tempData.item_name === value.item_name) {
          this.checkAndMatch(tempData, value);
          this.item_price_data.data[i].push(value);
          return;
        }
      }
      combineTableData(value, this.item_price_data.data);

    },

    checkAndMatch(tempData, value) {

      for (let i = 0; i < tempData.primary_attribute_values.length; i++) {
        let attr1 = tempData.primary_attribute_values[i];

        if (!value.primary_attribute_values.includes(attr1)) {
          value.primary_attribute_values.push(attr1);
          value.rate[attr1] = 0;
        }

      }

      for (let i = 0; i < value.primary_attribute_values.length; i++) {
        let attr1 = value.primary_attribute_values[i];

        if (!tempData.primary_attribute_values.includes(attr1)) {
          tempData.primary_attribute_values.push(attr1);
          tempData.rate[attr1] = 0;
        }

      }

    },

    fetchItemDetails() {
      this.item_price_data.display = false;
      this.item_price_data.data = [];
      this.item_price_data.originalData = null;
      this.display_uom.set_value(null);

      if (!this.price_list.get_value()) {
        show_error_alert("Please Select The Required Values");
        return;
      }

      if (!this.item_select.get_value()) {
        this.item_price_data.readOnly = true;
      } else {
        this.item_price_data.readOnly = false;
      }

      frappe.call({
        method: "essdee_sales.sales_order_management.doctype.price_list.price_list.get_item_price",
        args: {
          price_list: this.price_list.get_value(),
          item_name: this.item_select.get_value(),
        },
        freeze: true,
        freeze_msg: "Fetching Details...",
        callback: (response) => {
          response = response.message;
          response.data.forEach((data) => {
            this.makeDisplayableData(data);
          });
          this.item_price_data.defaultUom = response.default_unit_of_measure;
          this.item_price_data.display = true;
        },

      });
    },

    clearSelectedItem() {
      this.item_select.set_value(null);
    },

    validatePriceDetails(item, idx1, idx2) {

      if (item['primary_attribute']) {

        for (let i = 0; i < item['primary_attribute_values'].length; i++) {
          let key = item['primary_attribute_values'][i];
          let price = item['rate'][key];
          if (Number(price) < 0) {
            addError(`input_${idx1}${idx2}${key}`);
            show_error_alert(`Kindly Enter Valid Price For Item ${item['item_name']}`);
            return false;
          } else {
            removeError(`input_${idx1}${idx2}${key}`);
          }

        }

      } else {

        let price = item['rate']['default'];

        if (Number(price) < 0) {
          addError(`input_${idx1}${idx2}default`);
          show_error_alert(`Kindly Enter Valid Price For Item ${item['item_name']}`);
          return false;
        } else {
          removeError(`input_${idx1}${idx2}default`);
        }

      }

      return true;

    },

    saveItemPriceList() {
      let data = [];

      if (!this.price_list.get_value()) {
        show_error_alert("Select The Price List");
        return;
      }

      for (let i = 0; i < this.item_price_data.data.length; i++) {
        for (let j = 0; j < this.item_price_data.data[i].length; j++) {

          if (this.validatePriceDetails(this.item_price_data['data'][i][j], i, j)) {
            data.push(this.item_price_data.data[i][j]);
          } else {
            return;
          }

        }
      }

      frappe.call({
        method: "essdee_sales.sales_order_management.doctype.price_list.price_list.save_price_list",
        args: {
          item_price_data: JSON.stringify(data),
          price_list: this.price_list.get_value(),
        },
        freeze: true,
        freeze_msg: "Saving...",
        callback: (response) => {
          if (response.message.status === 200) {
            show_success_alert("Prices Updated Successfully");
            this.item_select.set_value(null);
          }
        },
      });

    },
  },

  mounted() {
    this.createPriceListSelect();
    this.createItemSelect();
    this.createUOMSelect();
  },

};

</script>


<style scoped>
.row-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.md-5 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.item-head {
  padding: 1rem;
  font-weight: bold;
}

input[type='number'] {
  max-width: 5rem;
  align-self: center;
}

table {
  width: 100%;
}

.btn-split {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

::-webkit-file-upload-button {
  display: none;
}
</style>
